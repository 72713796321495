import React from "react";
  
const LegendItem = ({
    title,
    color,
    style,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}>
            <span
                style={{
                    display: 'inline-block',
                    height: 15,
                    width: 15,
                    backgroundColor: color,
                    border: '1px solid grey',
                    marginRight: 7,
                    ...style
                }} />
            <span>{title}
            </span>
        </div>
    );
  }
  
  export default LegendItem;