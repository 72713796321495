const sample = {
        "compactnessScore": "A",
        "countySplitsScore": "PASS",
        "geographicScore": "A",
        "racialFairnessScore": "PASS",
        "competitivenessScore": "PASS",
        "demAdvPartisanArr": {
            "Seats": "PASS",
            "PB": "PASS",
            "MM": "PASS",
            "AvgDiff": "PASS"
        },
        "demAdvPartisanFairness": "A",
        "repAdvPartisanArr": {
            "Seats": "PASS",
            "PB": "PASS",
            "MM": "PASS",
            "AvgDiff": "FAIL"
        },
        "repAdvPartisanFairness": "B",
        "overallGrade": "A"
    };

// based on https://github.com/PrincetonUniversity/EIL-Dashboard/blob/master/NC/NC%20Report%20Cards/Report%20Cards/NC%20Cong19Dist%20score.json 
export default class DashboardReportCard {
    constructor(jsonObj, state, planName, sampleData=false) {
        if (sampleData) {
            jsonObj = sample;
        }
        this.state = state;
        this.planName = planName;
        this.geographic = jsonObj['geographicScore'] ? jsonObj['geographicScore'] : null;
        this.compactness = jsonObj['compactnessScore'] ? jsonObj['compactnessScore'] : null;
        this.countySplits = jsonObj['countySplitsScore'] ? jsonObj['countySplitsScore'] : null;
        this.competitiveness = jsonObj['competitivenessScore'] ? jsonObj['competitivenessScore'] : null;
        this.minorityComposition = jsonObj['minorityCompositionScore'] ? jsonObj['minorityCompositionScore'] : null;
        this.demAdvPartisanFairness = jsonObj['demAdvPartisanFairness'] ? jsonObj['demAdvPartisanFairness'] : null;
        this.repAdvPartisanFairness = jsonObj['repAdvPartisanFairness'] ? jsonObj['repAdvPartisanFairness'] : null;
        /*
            factors => { Seats, PB, MM, AvgDiff }
        */
        this.demAdvPartisanFactors = jsonObj['demAdvPartisanArr'] ? jsonObj['demAdvPartisanArr'] : null;
        this.repAdvPartisanFactors = jsonObj['repAdvPartisanArr'] ? jsonObj['repAdvPartisanArr'] : null;

        this.EnsemblePartisanScore = jsonObj['EnsemblePartisanScore'] ? jsonObj['EnsemblePartisanScore'] : null;
        this.EnsemblePartisanAdvantage = jsonObj['EnsemblePartisanAdvantage'] ? jsonObj['EnsemblePartisanAdvantage'] : null;
        this.SymmPartisanScore = jsonObj['SymmPartisanScore'] ? jsonObj['SymmPartisanScore'] : null;
        this.SymmPartisanAdvantage = jsonObj['SymmPartisanAdvantage'] ? jsonObj['SymmPartisanAdvantage'] : null;
        this.partisanScore = jsonObj['partisanScore'] ? jsonObj['partisanScore'] : null;
        this.partisanAdvantage = jsonObj['partisanAdvantage'] ? jsonObj['partisanAdvantage'] : null;

        this.overall = jsonObj['overallGrade'] ? jsonObj['overallGrade'] : null;
        this.finalReportCardGrade = jsonObj['finalReportCardGrade'] ? jsonObj['finalReportCardGrade'] : null;
    }

    getPartisanAdvantageWithFullPartyName = () => {
        switch (this.partisanAdvantage && this.partisanAdvantage.toUpperCase()) {
            case "DEM":
                return "Democratic";
            case "REP":
                return "Republican"
            default:
                return this.partisanAdvantage;
        }
    }

    getPartisanGradeDescription = () => {
        let output = ``;
        switch (this.partisanScore) {
            case "A":
                if (this.getPartisanAdvantageWithFullPartyName()) {
                    output = `Slight ${this.getPartisanAdvantageWithFullPartyName()} advantage.`;
                    break;
                }
                output = "No advantage";
                break;
            case "B": 
                if (this.competitiveness === 'A') {
                    output = `${this.getPartisanAdvantageWithFullPartyName()} advantage.`;
                }
                if (this.competitiveness === 'C') {
                    output = `Slight ${this.getPartisanAdvantageWithFullPartyName()} advantage.`;
                }
                break;
            case "C":
                if (this.competitiveness === 'A') {
                    output = `Significant ${this.getPartisanAdvantageWithFullPartyName()} advantage.`;
                }
                if (this.competitiveness === 'C') {
                    output = `${this.getPartisanAdvantageWithFullPartyName()} advantage.`;
                }
                if (this.competitiveness === 'F') {
                    output = `Slight ${this.getPartisanAdvantageWithFullPartyName()} advantage.`;
                }
                break;
            case "F":
                output = `Significant ${this.getPartisanAdvantageWithFullPartyName()} advantage.`;
                break;
            case "\*":
                output = `Mixed partisan advantage*`
                break;
        }
        if (this.competitiveness === `F`
            && (this.state !== "PA" && !(["H21_1Dist"].includes(this.planName)))) {
            output += ` Advantages incumbents`
        }
        return output;
    }

    getCompetitivenessGradeDescription = () => {
        switch (this.competitiveness) {
            case "A":
                return "Very competitive relative to other maps that could have been drawn";
            case "C":
                return "Similarly competitive relative to other maps that could have been drawn";
            case "F":
                if (this.state === "PA" && ["H21_1Dist"].includes(this.planName)) {
                    return "Uncompetitive relative to other maps that could have been drawn";
                }
                return "Very uncompetitive relative to other maps that could have been drawn";
            default:
                return "";
        }
    }

    getGeographicGradeDescription = () => {
        let output = "";
        switch (this.compactness) {
            case "A":
                output += "Very compact districts";
                break;
            case "C":
                output += "Compact districts";
                break;
            case "F":
                output += "Non-compact districts";
                break;
        }
        switch (this.countySplits) {
            case "A":
                output += ", few county splits";
                break;
            case "C":
                output += ", typical number of county splits";
                break;
            case "F":
                output += ", more county splits than typical";
                break;
        }
        return output;
    }

    getMinorityGradeDescription = () => {
        switch (this.minorityComposition) {
            case "B":
                return "Map may over-pack minority coalition districts";
            case "C":
                return "Map may over-pack minority opportunity districts";
            case "F":
                return "Map may backslide in minority opportunity districts";
            default:
                return null;
        }
    }
}
