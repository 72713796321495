import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { navigate } from "@reach/router";
import Select from "react-select";
import { MDXRenderer } from "gatsby-plugin-mdx";

import NumberLine from "../charts/NumberLine";
import MapboxContainer from "../MapboxContainer.jsx";
import CompetitivenessGraph from "../charts/Competitiveness";
import DashboardScores, {
  AVAILABLE_ELEX,
  DISTRICT_LEVEL_FIELDS,
} from "../../common/class/dashboard-scores";
import VAPGraph from "../charts/VAP";
import ReportCard, { getReportCardScoreColor } from "../charts/ReportCard";
import Expandable, { ExpandableHeader } from "../ExpandableSection";
import { COMPETITIVENESS_COLORS } from "../../common/constants/colors-dashboard";
import DashboardResults from "../../common/class/dashboard-results";
import ExpandableText from "../../components/ExpandableText";
import RedistrictingDashboardTooltipData from "./RedistrictingDashboardTooltipData";
import RedistrictingDashboardTooltipInfo from "./RedistrictingDashboardTooltipInfo";

import "../../styles/redistricting-dashboard.css";
import STATENAMES from "../../data/state_abbrev_to_name.json";
import STATEFIPS from "../../data/states_fips.json";
import HistoBar from "../charts/HistoBar";

import eilLogoImage from "../../img/eil.png";
// import repusLogoImage from "../../img/repus-logo.svg";
import arrowDown from "../../img/arrow-down.svg";
import mouseDownImage from "../../img/mouse-down.png";
import LinksList from "../LinksList";

const RELATIVE_ROOT_PATH = "/redistricting-report-card";

const RedistrictingDashboardContainer = props => {
  const contextdata = useStaticQuery(graphql`
    query dashboardPageQuery {
      allAirtableDashboard(
        filter: {
          table: { eq: "Dashboard" }
          data: { DashboardPlanStatus: { ne: "Hidden" } }
        }
      ) {
        group(field: data___DashboardPlanStateAbbreviation) {
          nodes {
            id
            table
            data {
              DashboardPlanMapURL
              DashboardPlanScoreURL
              DashboardPlanSourceURL
              DashboardPlanName
              DashboardPlanStateName
              DashboardPlanStateAbbreviation
              ShortDescription
              DashboardPlanDescription {
                childMdx {
                  body
                }
              }
              DashboardPlanState
              DashboardPlanStatus
              DashboardPlanYear
              DashboardFinalPlan
              DashboardTabledPlan
              GeneratedMapURL
              GeneratedScoreURL
              Last_Modified
              Created
              DateAdded
            }
            recordId
          }
          totalCount
          field
          fieldValue
        }
        totalCount
      }
      airtableDashboardAnnotations(data: { AnnotationID: { eq: 1 } }) {
        data {
          AnnotationID
          AnnotationSection
          AnnotationName
          AnnotationText {
            id
            childMdx {
              body
            }
          }
        }
        id
      }
    }
  `);
  const [mouseLocation, setMouseLocation] = useState({ x: null, y: null });
  const [selectedIDs, SetSelectedIDs] = useState([]);
  const [selectedPlan, SetSelectedPlan] = useState(null);
  const [SelectedStateFromMap, setSelectedStateFromMap] = useState(null);
  const [selectedVAP, SetSelectedVAP] = useState(DISTRICT_LEVEL_FIELDS.BVAP);
  // const [tooltipVAP, setTooltipVAP] = useState(DISTRICT_LEVEL_FIELDS.BVAP);
  const [tooltipValue, setTooltipValue] = useState(DISTRICT_LEVEL_FIELDS.BVAP);
  // TODO - select multiple plans (2? 3?)
  // const [selectedPlans, setSelectedPlans] = useState([]);
  const [ScoreDataURL, setScoreDataURL] = useState(null);
  const [MapDataURL, setMapDataURL] = useState(null);
  const [isLoadingScoreData, setIsLoadingScoreData] = useState(false);
  const [isLoadingMapData, setIsLoadingMapData] = useState(false);
  const [isErrorScoreData, setIsErrorScoreData] = useState(false);
  const [isErrorMapData, setIsErrorMapData] = useState(false);
  const [data, setData] = useState(null); //new DashboardScores({}));
  const [rawData, setRawData] = useState(null);
  const [reportCard, setReportCard] = useState(null); //new DashboardReportCard({}))
  const [thresholds, setThresholds] = useState(null); //new DashboardThresholds({}))
  const [mapData, setMapData] = useState(null);
  const [winFrequencyParty, setWinFrequencyParty] = useState("Democratic");
  const [selectedAnnotationID, setSelectedAnnotationID] = useState(null);
  const [showReportCard, setShowReportCard] = useState(false);
  const [availableVAPs, SetAvailableVAPs] = useState([]);
  const [isExpandedMapDownload, setIsExpandedMapDownload] = useState(false);
  const VAPAnnotationIDs = {
    [DISTRICT_LEVEL_FIELDS.BVAP]: 44,
    [DISTRICT_LEVEL_FIELDS.HVAP]: 45,
    [DISTRICT_LEVEL_FIELDS.AVAP]: 46,
    [DISTRICT_LEVEL_FIELDS.NVAP]: 47,
    [DISTRICT_LEVEL_FIELDS.PVAP]: 48,
    [DISTRICT_LEVEL_FIELDS.MVAP]: 49,
  };

  const onExploreMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName("exploreDropdown__option--is-selected")[0];
      if(selectedEl){
        selectedEl.scrollIntoView({block: 'nearest'});
      }
    }, 15);
  };

  useEffect(() => {
    let VAPS = [];
    if (data) {
      if (data.BVAPDistricts) {
        VAPS.push(DISTRICT_LEVEL_FIELDS.BVAP);
      }
      if (data.HVAPDistricts) {
        VAPS.push(DISTRICT_LEVEL_FIELDS.HVAP);
      }
      if (data.AVAPDistricts) {
        VAPS.push(DISTRICT_LEVEL_FIELDS.AVAP);
      }
      if (data.NVAPDistricts) {
        VAPS.push(DISTRICT_LEVEL_FIELDS.NVAP);
      }
      if (data.PVAPDistricts) {
        VAPS.push(DISTRICT_LEVEL_FIELDS.PVAP);
      }
      if (data.MVAPDistricts) {
        VAPS.push(DISTRICT_LEVEL_FIELDS.MVAP);
      }
    }
    SetAvailableVAPs(VAPS);
  }, [data]);

  const handleToggleSelectedID = id => {
    if (selectedIDs.includes(id)) {
      SetSelectedIDs([]);
    } else {
      SetSelectedIDs([id]);
    }
  };
  const selectPlanFromId = id => {
    let planToSelect = null;
    contextdata.allAirtableDashboard.group.forEach(groupObj => {
      groupObj.nodes.forEach(planObj => {
        if (planObj.recordId === id) {
          planToSelect = {
            ...planObj.data,
            label: planObj.data["DashboardPlanName"],
            id: planObj.recordId,
            // id: planObj.id,
          };
        }
      });
    });
    return planToSelect;
  };
  useEffect(() => {
    if (props.planId) {
      SetSelectedPlan(selectPlanFromId(props.planId));
    } else {
      SetSelectedPlan(null);
    }
  }, [props.planId]);

  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        setIsLoadingScoreData(true);
        const scoreResponse = await fetch(ScoreDataURL);
        const scoreData = await scoreResponse.json();
        setIsLoadingScoreData(false);

        if (scoreData["plan"]) {
          setRawData(scoreData);
          const results = new DashboardResults(scoreData);
          setData(results.plan);
          setReportCard(results.reportCard);
          setShowReportCard(results.reportCard ? true : false);
          setThresholds(results.thresholds);
        } else {
          setData(new DashboardScores(scoreData));
          setShowReportCard(false);
        }
      } catch (err) {
        setIsErrorScoreData(true);
      }
    };

    setData(null);
    setReportCard(null);
    setThresholds(null);

    if (ScoreDataURL) {
      fetchPlanData();
    }
  }, [ScoreDataURL]);

  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        setIsLoadingMapData(true);
        const mapResponse = await fetch(MapDataURL);
        const mapData = await mapResponse.json();
        setIsLoadingMapData(false);
        setMapData(mapData);
      } catch (err) {
        setIsErrorMapData(true);
      }
    };
    setMapData(null);
    if (MapDataURL) {
      fetchPlanData();
    }
  }, [MapDataURL]);

  useEffect(() => {
    SetSelectedIDs([]);

    if (selectedPlan) {
      props.setPageTitle(selectedPlan[`DashboardPlanName`]);
      setScoreDataURL(selectedPlan["GeneratedScoreURL"]);
      setMapDataURL(selectedPlan["GeneratedMapURL"]);
      SetSelectedVAP(DISTRICT_LEVEL_FIELDS.BVAP);
      setWinFrequencyParty(`Democratic`);
    } else {
      // map url passed directly via url param
      if (props.planMapUrl) {
        setMapDataURL(props.planMapUrl);
      } else {
        setMapDataURL(null);
      }

      // url passed directly via url param
      if (props.planUrl) {
        setScoreDataURL(props.planUrl);
      } else {
        setScoreDataURL(null);
      }
      SetSelectedVAP(DISTRICT_LEVEL_FIELDS.BVAP);
      setWinFrequencyParty(`Democratic`);
    }
  }, [selectedPlan, props.planUrl, props.planMapUrl]);

  useEffect(() => {
    const updateExpandButton = () => {
      if (isExpandedMapDownload) setIsExpandedMapDownload(false)
    }
    document.addEventListener('click', updateExpandButton)
    return () => document.removeEventListener('click', updateExpandButton)
  }, [isExpandedMapDownload]);

  return (
    <div className='redistricting-container-parent' onClick={() => setSelectedStateFromMap(null)}>
      <div className="redistricting-dashboard-header">
        <h1
          role="button"
          tabIndex="0"
          onClick={() => navigate(RELATIVE_ROOT_PATH)}
        >
          Redistricting Report Card
        </h1>
      </div>
      {!data && !isLoadingScoreData && (
        // empty
        <div style={{ margin: "30px 0"}}>Loading...</div>
      )}
      {selectedPlan &&
        <h2 className='main-title'>
          {selectedPlan[`DashboardPlanName`]}
          {selectedPlan[`DashboardFinalPlan`] && (
            <span
              style={{
                marginLeft: 5,
                fontSize: 12,
                background: "lightgrey",
                borderRadius: 5,
              }}
            >
                      Final
                    </span>
          )}
          {selectedPlan[`DashboardTabledPlan`] && (
            <span
              style={{
                marginLeft: 5,
                fontSize: 12,
                background: "lightgrey",
                borderRadius: 5,
              }}
            >
                      Tabled
                    </span>
          )}
        </h2>
      }

      {data && (
        <section
          className={`redistricting-dashboard redistricting-dashboard-top`}
        >
          <section
            className={"card redistricting-dashboard-map"}
            style={
              props.customDashboard
                ? {
                    gridColumn: `span 6`,
                  }
                : {}
            }
          >
            {selectedPlan && (
              <>
                {/* <StateMap stateName={selectedPlan['DashboardPlanStateAbbreviation'][0]} /> */}
                {isLoadingMapData && (
                  <div style={{ margin: "30px 0"}}>
                    <h2>Map data loading...</h2>
                  </div>
                )}
                {/*{selectedPlan[`DashboardPlanStatus`] === "Private" &&*/}
                {/*  "🔒 Private, unpublished report card"}*/}
                {/*{reportCard && selectedPlan[`DashboardPlanDescription`] && (*/}
                {/*  <ExpandableText*/}
                {/*    title={`additional details`}*/}
                {/*    collapsedDefault={false ? true : false}*/}
                {/*  >*/}
                {/*    <MDXRenderer>*/}
                {/*      {selectedPlan[`DashboardPlanDescription`].childMdx.body}*/}
                {/*    </MDXRenderer>*/}
                {/*  </ExpandableText>*/}
                {/*)}*/}
                {!isLoadingMapData && !mapData && <h3 style={{ margin: "30px 0"}}>No map to display :(</h3>}
              </>
            )}
            {data && mapData && (
              <>
                <div className='tool-tip-value'>
                  <label>Tooltip value:</label>
                  <select
                    value={tooltipValue}
                    onChange={e => setTooltipValue(e.target.value)}
                  >
                    {[...availableVAPs, ...Object.keys(AVAILABLE_ELEX)].map(
                      value => {
                        return (
                          <option id={value} key={value} value={value}>
                            {value.toLocaleUpperCase()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <RedistrictingDashboardTooltipInfo.Mark
                    setMouseLocation={setMouseLocation}
                    setSelectedAnnotationID={setSelectedAnnotationID}
                    annotationName={selectedVAP}
                    annotationID={
                      VAPAnnotationIDs[tooltipValue]
                        ? VAPAnnotationIDs[tooltipValue]
                        : 44
                    }
                  />
                </div>
                <MapboxContainer
                  customDashboard={props.customDashboard}
                  stateFipsCode={
                    selectedPlan
                      ? STATEFIPS[
                          selectedPlan[`DashboardPlanStateAbbreviation`]
                        ]
                      : STATEFIPS[props.stateAbbrev]
                  }
                  SetSelectedVAP={SetSelectedVAP}
                  setMouseLocation={setMouseLocation}
                  xValueField={`district`}
                  yValueField={`demVoteshare`}
                  selectedIDs={selectedIDs}
                  SetSelectedIDs={SetSelectedIDs}
                  handleToggleSelectedID={handleToggleSelectedID}
                  data={data.getDistrictDataObject()}
                  mapData={mapData}
                  MapDataURL={MapDataURL}
                />
              </>
            )}
            <div className='map-bottom-buttons'>
              <div>
                {selectedPlan[`DashboardPlanSourceURL`] && (
                  <OutboundLink
                    style={{ fontWeight: "700", fontSize: "1rem"}}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={selectedPlan[`DashboardPlanSourceURL`]}
                  >
                    Link to plan source
                  </OutboundLink>
                )}
              </div>
              <div className='download-menu-container'>
                <button onClick={() => setIsExpandedMapDownload((prev) => !prev) } aria-expanded={isExpandedMapDownload} className='download__button'>
                  Download
                  <img src={arrowDown} alt=""/>
                </button>
                <div className={`download-menu ${isExpandedMapDownload ? ' isExpanded' : ''}`}>
                  <ul>
                    <li>
                      <OutboundLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href={MapDataURL}
                        download
                      >
                        Map Data
                      </OutboundLink>
                    </li>
                    <li>
                      <OutboundLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href={ScoreDataURL}
                        download
                      >
                        Score Data
                      </OutboundLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {props.pageTitle && <div>{props.pageTitle}</div>}
          {props.planUrl && !props.planId && (
            <div>
              plan url:{" "}
              <input
                type="textarea"
                onChange={e => props.setPlanUrl(e.target.value)}
                value={props.planUrl}
              />
            </div>
          )}
          {props.planMapUrl && ( // !props.planId &&
            <div>
              plan map url:{" "}
              <input
                type="textarea"
                onChange={e => props.setPlanMapUrl(e.target.value)}
                value={props.planMapUrl}
              />
            </div>
          )}
          <aside className={"card"}>
            {selectedPlan && reportCard && (
              <div>
                {reportCard.finalReportCardGrade && (
                  <h3 className="score-title left-mobile" id="finalReportCardGrade">
                    Overall Grade
                  </h3>
                )}
                <div className='overall-instructions-container'>
                  {reportCard.finalReportCardGrade && (
                    <div className={`overall-grade ${reportCard.finalReportCardGrade.length > 1 ? 'small-grade' : ''}`}>
                      {reportCard.finalReportCardGrade}
                    </div>
                  )}
                  <div className='overall-instructions'>
                    <img src={mouseDownImage} alt="" />
                    <p>Scroll down to see more information about the grade</p>
                  </div>
                </div>
              </div>
            )}
          </aside>
          {selectedIDs.length > 0 &&
            selectedIDs[0] &&
            !selectedAnnotationID &&
            mouseLocation.x &&
            mouseLocation.y && (
              <RedistrictingDashboardTooltipData
                mouseLocation={mouseLocation}
                data={data}
                selectedIDs={selectedIDs}
                SetSelectedIDs={SetSelectedIDs}
                selectedValue={tooltipValue}
              />
            )}
          {selectedAnnotationID && mouseLocation.x && mouseLocation.y && (
            <RedistrictingDashboardTooltipInfo
              mouseLocation={mouseLocation}
              selectedAnnotationID={selectedAnnotationID}
            />
          )}
        </section>
      )}
            <section className='explore-other-maps'>
        <h2>Explore other maps</h2>
        <Select
          onChange={(value, { action }) => {
            switch (action) {
              case "clear":
                navigate(RELATIVE_ROOT_PATH);
                break;
              case "select-option":
              default:
                navigate(`?planId=${value.id}`);
            }
          }}
          isLoading={isLoadingMapData || isLoadingScoreData}
          isClearable={true}
          isSearchable={true}
          menuIsOpen={SelectedStateFromMap ?? undefined}
          onMenuOpen={onExploreMenuOpen}
          value={selectedPlan || null}
          className={"exploreDropdown"}
          classNamePrefix={"exploreDropdown"}
          getOptionValue={option => option["id"]}
          options={contextdata.allAirtableDashboard.group
            .filter(groupObj => {
              if (SelectedStateFromMap) {
                return (
                  SelectedStateFromMap &&
                  groupObj["fieldValue"] === SelectedStateFromMap
                );
              }
              return true;
            })
            .map(groupObj => {
              return {
                label: STATENAMES[groupObj["fieldValue"]],
                options: groupObj.nodes
                  .filter(
                    planObj =>
                      planObj.data["DashboardPlanStatus"] !== "Private"
                  )
                  .map(planObj => {
                    return {
                      ...planObj.data,
                      label: planObj.data["DashboardPlanName"],
                      id: planObj.recordId,
                      // id: planObj.id,
                    };
                  }),
              };
            })}
        />
      </section>
      {selectedPlan && reportCard && (
        <section className="restricting-dashboard-score">
          <h2>Score</h2>
          <div className="score-grid">
            {reportCard && <ReportCard reportCard={reportCard} />}
            {!reportCard && selectedPlan[`DashboardPlanDescription`] && (
              <ExpandableText
                title={`additional details`}
                collapsedDefault={false}
              >
                <MDXRenderer>
                  {selectedPlan[`DashboardPlanDescription`].childMdx.body}
                </MDXRenderer>
              </ExpandableText>
            )}
          </div>
        </section>
      )}
      {
      selectedPlan && selectedPlan['ShortDescription'] && 
        <section className="score-description">
          <div dangerouslySetInnerHTML={{__html: selectedPlan['ShortDescription']}} />
        </section>
        }
      {data && (
        <>
          <h2 id="dashboard">Metrics</h2>
          <section className={`redistricting-dashboard`}>
            <>
              <div className={"card"}>
                {data ? (
                  <Expandable
                    isExpandedDefault={true}
                    ariaLabel="Tap to see the stats behind this score"
                  >
                    <ExpandableHeader
                      isExpandDisabled={true}
                      style={{
                        background: "lightgrey",
                        // background: showReportCard && reportCard && REPORT_CARD_COLORS[reportCard.partisanScore.toLocaleUpperCase()]
                      }}
                    >
                      <h2 id="partisan-fairness">
                        Partisan Fairness
                        <RedistrictingDashboardTooltipInfo.Mark
                          setMouseLocation={setMouseLocation}
                          setSelectedAnnotationID={setSelectedAnnotationID}
                          annotationName={"Partisan Fairness"}
                          annotationID={2}
                        />
                      </h2>
                      {showReportCard && reportCard && (
                        <>
                          <div
                            style={{
                              backgroundColor: getReportCardScoreColor(
                                reportCard ? reportCard.partisanAdvantage : ""
                              ),
                              color: "whitesmoke",
                            }}
                            className={`grade-label`}
                          >
                            <RedistrictingDashboardTooltipInfo.Mark
                              setMouseLocation={setMouseLocation}
                              setSelectedAnnotationID={setSelectedAnnotationID}
                              annotationName={`Advantage ${reportCard.getPartisanAdvantageWithFullPartyName()}`}
                              annotationID={4}
                            />
                            Advantage:{" "}
                            {reportCard.getPartisanAdvantageWithFullPartyName()}
                          </div>
                          <div className={`grade-value`}>
                            <RedistrictingDashboardTooltipInfo.Mark
                              setMouseLocation={setMouseLocation}
                              setSelectedAnnotationID={setSelectedAnnotationID}
                              annotationName={`Partisan Fairness Grade`}
                              annotationID={3}
                            />
                            {reportCard.partisanScore}
                          </div>
                        </>
                      )}
                    </ExpandableHeader>
                    <div className="card-content">
                      <>
                        {thresholds && thresholds.winsFreq ? (
                          <div>
                            <strong>
                              Frequency of{" "}
                              <select
                                onChange={e =>
                                  setWinFrequencyParty(e.target.value)
                                }
                              >
                                <option value={`Democratic`}>
                                  Democratic wins
                                </option>
                                <option value={`Republican`}>
                                  Republican wins
                                </option>
                              </select>
                              out of {data.numDists} districts
                              <RedistrictingDashboardTooltipInfo.Mark
                                setMouseLocation={setMouseLocation}
                                setSelectedAnnotationID={
                                  setSelectedAnnotationID
                                }
                                annotationName={`Frequency of ${winFrequencyParty} Wins`}
                                annotationID={
                                  winFrequencyParty === `Republican` ? 62 : 60
                                }
                              />
                            </strong>
                            <div>
                              <em>Powered by up to 1 million maps</em>
                            </div>
                            <HistoBar
                              data={
                                winFrequencyParty === `Republican`
                                  ? thresholds.winsFreqReverse
                                  : thresholds.winsFreq
                              }
                              value={
                                winFrequencyParty === `Republican`
                                  ? data.numDists - data.ActualWins
                                  : data.ActualWins
                              }
                              height={150}
                              numDists={data.numDists}
                              xLabel={`→# of ${winFrequencyParty.substring(
                                0,
                                1
                              )} districts (/${data.numDists})`}
                              yLabel={"↑# of maps"}
                              barFillColor={"grey"}
                              barFillOpacity={0.15}
                              barStrokeColor={"black"}
                              secondaryRange={
                                winFrequencyParty === `Republican`
                                  ? thresholds.getReverseSymmetryColorScale()
                                  : thresholds.getSymmetryColorScale()
                              }
                              secondaryRangeLabel={"Fair range"}
                              showBands={true ? true : false}
                              bands={
                                winFrequencyParty === `Republican`
                                  ? thresholds.getReversePartisanGradesColorScale()
                                  : thresholds.getPartisanGradesColorScale()
                              }
                            />
                          </div>
                        ) : (
                          <NumberLine
                            title={"Democratic seat share"}
                            lower={0}
                            lowerLabel=""
                            upper={data.numDists}
                            upperLabel=""
                            decimalPlace={0}
                            value={data.ActualWins}
                            valueLabel={""}
                            showMin={true}
                            showMax={true}
                            secondaryRange={
                              thresholds && thresholds.getSymmetryColorScale()
                            }
                            scaleValues={
                              thresholds && thresholds.getSeatShareColorScale()
                            }
                            showMidpointLine={false}
                            showScaleGradient={showReportCard}
                            showTickmarks={false}
                          />
                        )}
                      </>
                      {thresholds ? (
                        <NumberLine
                          title={
                            <React.Fragment>
                              Competitiveness
                              <RedistrictingDashboardTooltipInfo.Mark
                                setMouseLocation={setMouseLocation}
                                setSelectedAnnotationID={
                                  setSelectedAnnotationID
                                }
                                annotationName={`Competitiveness`}
                                annotationID={10}
                              />
                            </React.Fragment>
                          }
                          lower={0}
                          lowerLabel="Min"
                          upper={data.numDists}
                          upperLabel="Max"
                          decimalPlace={0}
                          value={data.ActualCompetitive}
                          valueLabel=""
                          showMin={false}
                          showMax={false}
                          scaleBin={true}
                          scaleValues={
                            thresholds &&
                            thresholds.getCompetitivenessColorScale()
                          }
                          showMidpointLine={false}
                          showScaleGradient={showReportCard}
                          onlyShowValueOnHover={false}
                          showTickmarks={false}
                        />
                      ) : (
                        <NumberLine
                          title={
                            <React.Fragment>
                              Competitive Districts
                              {/* <RedistrictingDashboardTooltipInfo.Mark
                              setMouseLocation={setMouseLocation}
                              setSelectedAnnotationID={setSelectedAnnotationID}
                              annotationName={`Competitiveness`}
                              annotationID={10}
                            /> */}
                            </React.Fragment>
                          }
                          lower={0}
                          lowerLabel=""
                          upper={data.numDists}
                          upperLabel=""
                          decimalPlace={0}
                          value={data.ActualCompetitive}
                          valueLabel=""
                          showMin={true}
                          showMax={true}
                          scaleBin={true}
                          // scaleValues={thresholds && thresholds.getCompetitivenessColorScale()}
                          showMidpointLine={false}
                          showScaleGradient={showReportCard}
                          onlyShowValueOnHover={false}
                          showTickmarks={false}
                        />
                      )}
                      <div>
                        <strong>Additional metrics</strong>
                        {data.state === "NJ" ||
                        (data.state !== "VA" &&
                          data.PlanDemVoteshare > 0.45 &&
                          data.PlanDemVoteshare < 0.55 &&
                          data.numDists >= 7) ? (
                          <div className={"partisan-card-grid"}>
                            <div className='grid-score'>
                              <div>
                                Packed Wins
                                <RedistrictingDashboardTooltipInfo.Mark
                                  setMouseLocation={setMouseLocation}
                                  setSelectedAnnotationID={
                                    setSelectedAnnotationID
                                  }
                                  annotationName={"Packed Wins"}
                                  annotationID={14}
                                />
                              </div>
                              <div className={"grid-score-value"}>
                                {!data.avgDemWin && !data.avgRepWin && (
                                  <p>N/A</p>
                                )}
                                {data.avgDemWin && data.avgRepWin ? (
                                  data.avgDemWin !== 0 &&
                                  data.avgRepWin !== 0 &&
                                  Number.parseFloat(
                                    data.avgDemWin * 100
                                  ).toPrecision(5) ===
                                    Number.parseFloat(
                                      data.avgRepWin * 100
                                    ).toPrecision(5) ? (
                                    <p>+/- 0%</p>
                                  ) : Number.parseFloat(
                                      data.avgDemWin * 100
                                    ).toPrecision(5) >
                                    Number.parseFloat(
                                      data.avgRepWin * 100
                                    ).toPrecision(5) ? (
                                    <p
                                      style={{
                                        color:
                                          COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN,
                                      }}
                                    >
                                      {Math.abs(
                                        Number.parseFloat(
                                          data.avgDemWin * 100 -
                                            data.avgRepWin * 100
                                        )
                                      ).toFixed(1)}
                                      % favoring R
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        color:
                                          COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT,
                                      }}
                                    >
                                      {Math.abs(
                                        Number.parseFloat(
                                          data.avgDemWin * 100 -
                                            data.avgRepWin * 100
                                        )
                                      ).toFixed(1)}
                                      % favoring D
                                    </p>
                                  )
                                ) : null}
                                {/* <div>D: {Number.parseFloat(data.avgDemWin*100).toPrecision(5)}%
                              </div>
                              <div>
                                R: {Number.parseFloat(data.avgRepWin*100).toPrecision(5)}%</div> */}
                              </div>
                            </div>
                            <div className={"grid-score"}>
                              <div>
                                Mean-Median
                                <RedistrictingDashboardTooltipInfo.Mark
                                  setMouseLocation={setMouseLocation}
                                  setSelectedAnnotationID={
                                    setSelectedAnnotationID
                                  }
                                  annotationName={"Mean-median"}
                                  annotationID={15}
                                />
                              </div>
                              <div className={"grid-score-value"}>
                                {Math.abs(
                                  Number.parseFloat(
                                    data.meanMedian * 100
                                  ).toFixed(1)
                                ) === 0 ? (
                                  // Number.parseFloat(data.meanMedian*100).toPrecision(5) === 0 ?
                                  <p>+/- 0%</p>
                                ) : Number.parseFloat(
                                    data.meanMedian * 100
                                  ).toPrecision(5) > 0 ? (
                                  <p
                                    style={{
                                      color:
                                        COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN,
                                    }}
                                  >
                                    +
                                    {Math.abs(
                                      Number.parseFloat(
                                        data.meanMedian * 100
                                      ).toFixed(1)
                                    )}
                                    % R
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      color:
                                        COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT,
                                    }}
                                  >
                                    +
                                    {Math.abs(
                                      Number.parseFloat(
                                        data.meanMedian * 100
                                      ).toFixed(1)
                                    )}
                                    % D
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className='grid-score'>
                              <div>
                                Partisan Bias
                                <RedistrictingDashboardTooltipInfo.Mark
                                  setMouseLocation={setMouseLocation}
                                  setSelectedAnnotationID={
                                    setSelectedAnnotationID
                                  }
                                  annotationName={"Partisan bias"}
                                  annotationID={16}
                                />
                              </div>
                              <div className={"grid-score-value"}>
                                {Math.abs(
                                  Number.parseFloat(
                                    data.partisanBias * 100
                                  ).toFixed(1)
                                ) === 0 ? (
                                  <p>+/- 0%</p>
                                ) : Number.parseFloat(
                                    data.partisanBias * 100
                                  ).toPrecision(5) > 0 ? (
                                  <p
                                    style={{
                                      color:
                                        COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN,
                                    }}
                                  >
                                    +
                                    {Math.abs(
                                      Number.parseFloat(
                                        data.partisanBias * 100
                                      ).toFixed(1)
                                    )}
                                    % R
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      color:
                                        COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT,
                                    }}
                                  >
                                    +
                                    {Math.abs(
                                      Number.parseFloat(
                                        data.partisanBias * 100
                                      ).toFixed(1)
                                    )}
                                    % D
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            Not calculated because this map has either fewer
                            than 7 districts or the voteshare is outside 45-55%
                            causing the metrics to be unreliable.
                          </div>
                        )}
                      </div>
                    </div>
                  </Expandable>
                ) : (
                  "Loading..."
                )}
              </div>
              <div className={"card"}>
                {/* TODO - generate numberline/bar for each selected item */}
                {data ? (
                  <Expandable
                    isExpandedDefault={true}
                    ariaLabel="Tap to see the stats behind this score"
                  >
                    <ExpandableHeader
                      isExpandDisabled={true}
                      style={{
                        background: "lightgrey",
                        // background: showReportCard && reportCard && REPORT_CARD_COLORS[reportCard.geographic.toLocaleUpperCase()]
                      }}
                    >
                      <h2 id="geographic">
                        Geographic Features
                        <RedistrictingDashboardTooltipInfo.Mark
                          setMouseLocation={setMouseLocation}
                          setSelectedAnnotationID={setSelectedAnnotationID}
                          annotationName={`Geographic Features`}
                          annotationID={18}
                        />
                      </h2>
                      {showReportCard && reportCard && reportCard.geographic && (
                        <div className={`grade-value`}>
                          <RedistrictingDashboardTooltipInfo.Mark
                            setMouseLocation={setMouseLocation}
                            setSelectedAnnotationID={setSelectedAnnotationID}
                            annotationName={`Geographic Features Grade`}
                            annotationID={19}
                          />
                          {reportCard.geographic}
                        </div>
                      )}
                    </ExpandableHeader>
                    <div className="card-content">
                      {/* <a href="https://mailchi.mp/0658c35bd252/introducing-the-princeton-mapcorps">
                        <em>
                          Geographic Features powered by the Princeton MapCorps
                        </em>
                      </a> */}
                      <NumberLine
                        title={
                          <React.Fragment>
                            Compactness (Avg. Reock)
                            <RedistrictingDashboardTooltipInfo.Mark
                              setMouseLocation={setMouseLocation}
                              setSelectedAnnotationID={setSelectedAnnotationID}
                              annotationName={`Compactness`}
                              annotationID={20}
                            />
                          </React.Fragment>
                        }
                        lower={0}
                        lowerLabel=""
                        upper={1}
                        upperLabel=""
                        decimalPlace={3}
                        value={Number(data.avgReock)}
                        valueLabel=""
                        showMin={true}
                        showMax={true}
                        scaleBin={true}
                        scaleValues={
                          thresholds && thresholds.getCompactnessColorScale()
                        }
                        showMidpointLine={false}
                        showScaleGradient={showReportCard}
                        onlyShowValueOnHover={false}
                        showTickmarks={false}
                      />
                      <NumberLine
                        title={
                          <React.Fragment>
                            County Splits
                            <RedistrictingDashboardTooltipInfo.Mark
                              setMouseLocation={setMouseLocation}
                              setSelectedAnnotationID={setSelectedAnnotationID}
                              annotationName={`Geographic Features Grade`}
                              annotationID={30}
                            />
                          </React.Fragment>
                        }
                        lower={data.lowerBoundCountySplits}
                        lowerLabel=""
                        upper={data.upperBoundCountySplits}
                        upperLabel=""
                        decimalPlace={0}
                        value={data.countySplits}
                        valueLabel=""
                        showMin={true}
                        showMax={true}
                        scaleBin={true}
                        scaleValues={
                          thresholds && thresholds.getCountySplitsColorScale()
                        }
                        lowerColor={`rgba(28, 220, 24,1)`}
                        middleColor={`rgba(204,204,204,1)`}
                        upperColor={`rgba(71, 11, 21,1)`}
                        showMidpointLine={false}
                        showScaleGradient={showReportCard}
                        showTickmarks={false}
                      />
                      <div>
                        <strong>Additional metrics</strong>
                        <div className={"partisan-card-grid"}>
                          <div className={"grid-score"}>
                            <div>
                              Min. Reock
                              <RedistrictingDashboardTooltipInfo.Mark
                                setMouseLocation={setMouseLocation}
                                setSelectedAnnotationID={
                                  setSelectedAnnotationID
                                }
                                annotationName={"Min. Reock"}
                                annotationID={25}
                              />
                            </div>
                            <p className={"grid-score-value"}>
                              {Number(data.minReock).toFixed(3)}
                            </p>
                          </div>
                          <div className={"grid-score"}>
                            <div>
                              Avg. Polsby-Popper
                              <RedistrictingDashboardTooltipInfo.Mark
                                setMouseLocation={setMouseLocation}
                                setSelectedAnnotationID={
                                  setSelectedAnnotationID
                                }
                                annotationName={"Avg. Polsby-Popper"}
                                annotationID={24}
                              />
                            </div>
                            <p className={"grid-score-value"}>
                              {Number(data.avgPolsbyPopper).toFixed(3)}
                            </p>
                          </div>
                          <div className={"grid-score"}>
                            <div>
                              Min. Polsby-Popper
                              <RedistrictingDashboardTooltipInfo.Mark
                                setMouseLocation={setMouseLocation}
                                setSelectedAnnotationID={
                                  setSelectedAnnotationID
                                }
                                annotationName={"Min. Polsby-Popper"}
                                annotationID={26}
                              />
                            </div>
                            <p className={"grid-score-value"}>
                              {Number(data.minPolsbyPopper).toFixed(3)}
                            </p>
                          </div>
                          {data.splitPairs && (
                            <div className={"grid-score"}>
                              <div>
                                Split Pairs
                                <RedistrictingDashboardTooltipInfo.Mark
                                  setMouseLocation={setMouseLocation}
                                  setSelectedAnnotationID={
                                    setSelectedAnnotationID
                                  }
                                  annotationName={"Split Pairs"}
                                  annotationID={57}
                                />
                              </div>
                              <p className={"grid-score-value"}>
                                {Number(data.splitPairs).toFixed(3)}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Expandable>
                ) : (
                  "Loading..."
                )}
              </div>
              <div className={"card"}>
                {data ? (
                  <Expandable
                    isExpandedDefault={true}
                    ariaLabel="Tap to see the stats behind this score"
                  >
                    <ExpandableHeader
                      isExpandDisabled={true}
                      style={{
                        background: "lightgrey",
                        // background: showReportCard && reportCard && REPORT_CARD_COLORS[reportCard.competitiveness.toLocaleUpperCase()]
                      }}
                    >
                      <h2 id="partisan-composition">
                        Partisan Composition
                        <RedistrictingDashboardTooltipInfo.Mark
                          setMouseLocation={setMouseLocation}
                          setSelectedAnnotationID={setSelectedAnnotationID}
                          annotationName={`Partisan Composition`}
                          annotationID={36}
                        />
                      </h2>
                      {/* <h2>{showReportCard && reportCard && reportCard.competitiveness && `Grade: ${reportCard.competitiveness}`}</h2> */}
                    </ExpandableHeader>
                    <div className="card-content">
                      <CompetitivenessGraph
                        customDashboard={props.customDashboard}
                        setMouseLocation={setMouseLocation}
                        setSelectedAnnotationID={setSelectedAnnotationID}
                        selectedIDs={selectedIDs}
                        SetSelectedIDs={SetSelectedIDs}
                        handleToggleSelectedID={handleToggleSelectedID}
                        data={data}
                      />
                      <div>
                        <label>Tooltip value:</label>
                        <select
                          value={tooltipValue}
                          onChange={e => setTooltipValue(e.target.value)}
                        >
                          {[
                            ...availableVAPs,
                            ...Object.keys(AVAILABLE_ELEX),
                          ].map(value => {
                            return (
                              <option id={value} key={value} value={value}>
                                {value.toLocaleUpperCase()}
                              </option>
                            );
                          })}
                        </select>
                        <RedistrictingDashboardTooltipInfo.Mark
                          setMouseLocation={setMouseLocation}
                          setSelectedAnnotationID={setSelectedAnnotationID}
                          annotationName={selectedVAP}
                          annotationID={
                            VAPAnnotationIDs[tooltipValue]
                              ? VAPAnnotationIDs[tooltipValue]
                              : 44
                          }
                        />
                      </div>
                    </div>
                  </Expandable>
                ) : (
                  "Loading..."
                )}
              </div>
              <div className={"card"}>
                {/* TODO - add alternate axis competitiveness charts (bvap, hvap) */}
                {data ? (
                  <Expandable
                    isExpandedDefault={true}
                    ariaLabel="Tap to see the stats behind this score"
                  >
                    <ExpandableHeader
                      isExpandDisabled={true}
                      style={{
                        background: "lightgrey",
                        // background: showReportCard && reportCard && REPORT_CARD_COLORS[reportCard.racialFairness.toLocaleUpperCase()]
                      }}
                    >
                      <div id="minority-composition">
                        <h2>
                          Minority Composition
                          <RedistrictingDashboardTooltipInfo.Mark
                            setMouseLocation={setMouseLocation}
                            setSelectedAnnotationID={setSelectedAnnotationID}
                            annotationName={`Minority Composition`}
                            annotationID={43}
                          />
                        </h2>
                        {reportCard &&
                          reportCard.getMinorityGradeDescription() && (
                            <em>
                              {reportCard &&
                                reportCard.getMinorityGradeDescription()}
                            </em>
                          )}
                      </div>
                      {/* <h2>{showReportCard && reportCard && reportCard.racialFairness && `Grade: ${reportCard.racialFairness}`}</h2> */}
                    </ExpandableHeader>
                    <div className="card-content">
                      <VAPGraph
                        setSelectedAnnotationID={setSelectedAnnotationID}
                        setMouseLocation={setMouseLocation}
                        selectedIDs={selectedIDs}
                        SetSelectedIDs={SetSelectedIDs}
                        selectedVAP={selectedVAP}
                        SetSelectedVAP={SetSelectedVAP}
                        handleToggleSelectedID={handleToggleSelectedID}
                        data={data}
                      />
                      <div>
                        <label>Tooltip value:</label>
                        <select
                          value={tooltipValue}
                          onChange={e => setTooltipValue(e.target.value)}
                        >
                          {availableVAPs.map(vap => {
                            return (
                              <option id={vap} key={vap} value={vap}>
                                {vap.toLocaleUpperCase()}
                              </option>
                            );
                          })}
                        </select>
                        <RedistrictingDashboardTooltipInfo.Mark
                          setMouseLocation={setMouseLocation}
                          setSelectedAnnotationID={setSelectedAnnotationID}
                          annotationName={selectedVAP}
                          annotationID={
                            VAPAnnotationIDs[tooltipValue]
                              ? VAPAnnotationIDs[tooltipValue]
                              : 44
                          }
                        />
                      </div>
                    </div>
                  </Expandable>
                ) : (
                  "Loading..."
                )}
              </div>
            </>
          </section>
        </>
      )}
      {selectedPlan &&
        <LinksList
          title='Helpful information'
          links = {[
            {
              href: `/reforms/${
                selectedPlan[`DashboardPlanStateAbbreviation`]
              }`,
              title: `Learn more about the ${selectedPlan[`DashboardPlanStateName`]} redistricting process`,
              isExternal: false
            },
            {
              href: `https://representable.org/map/${
                selectedPlan[`DashboardPlanStateAbbreviation`]
              }`,
              title: `View Communities of interest on Representable`,
              isExternal: true
            },
            {
              href: `https://representable.org/state/${
                selectedPlan[`DashboardPlanStateAbbreviation`]
              }`,
              title: 'Learn more about communities of interest on Representable.org',
              isExternal: true
            }
          ]}
        />
      }
      {props.customDashboard && rawData && rawData.plan && (
        <>
          <h2>All Data</h2>
          <div className="text-col">
            <table>
              <tbody>
                {Object.keys(rawData.plan).map(d => {
                  if (!rawData.plan[d]) return;
                  if (typeof rawData.plan[d] === "function") return;
                  return (
                    <tr>
                      <td>{d}</td>
                      {typeof rawData.plan[d] === "object" ? (
                        <td width={"500px"}>
                          <table width={"100%"}>
                            <tbody>
                              {Array.isArray(rawData.plan[d])
                                ? rawData.plan[d].map(r => {
                                    return (
                                      <tr>
                                        <td>{JSON.stringify(r)}</td>
                                      </tr>
                                    );
                                  })
                                : Object.keys(rawData.plan[d]).map(r => {
                                    return (
                                      <tr>
                                        <td>{r}</td>
                                        <td>
                                          {JSON.stringify(rawData.plan[d][r])}
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </tbody>
                          </table>
                          {/* <textarea width={'100%'}>{JSON.stringify(rawData.plan[d], null, 2)}</textarea> */}
                        </td>
                      ) : (
                        <td width={"500px"}>{rawData.plan[d]}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
      <h2>
        <Link to="/redistricting-report-card-methodology">
          Check out the Methodology we used for the Report Card
        </Link>
      </h2>
      {selectedPlan && (
        <>
          <div>
            Date added: {new Date(selectedPlan["DateAdded"]).toDateString()}
          </div>
          <div>
            Last modified:{" "}
            {new Date(selectedPlan["Last_Modified"]).toDateString()}
          </div>
        </>
      )}
      {/* <Expandable>
            <ExpandableHeader>
              <h2>Methodology</h2>
            </ExpandableHeader>
            {
              contextdata.airtableDashboardAnnotations && 
              contextdata.airtableDashboardAnnotations.data && 
              <MDXRenderer>{contextdata.airtableDashboardAnnotations.data['AnnotationText'].childMdx.body}</MDXRenderer>
            }
          </Expandable> */}
    </div>
  );
};

export default RedistrictingDashboardContainer;
