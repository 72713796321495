import React, { useEffect, useState, useRef } from 'react'
import * as d3 from 'd3';
import { Axis, Orient } from 'd3-axis-for-react';
import useDimensions from "react-cool-dimensions";
import { DISTRICT_LEVEL_FIELDS, DISTRICT_LEVEL_FIELD_NAMES } from '../../common/class/dashboard-scores';
import LegendItem from '../LegendItem';

const ScrollingScatterplotLegend = (props) => {
    return <div>
        {
            props.bands &&
            props.bands.length > 0 &&
            <>
            <strong>Zones</strong>
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>
            {
                props.bands.map((item, index) => {
                    return <LegendItem key={index} title={item.title} color={item.color} />
                })
            }
            </div>
            </>
        }
        {
            props.scale &&
            props.scale.length &&
            <>
            <strong>Scale</strong>
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>
            {
                props.scale.map((item, index) => {
                    return <LegendItem key={index} title={item.title} color={item.color} />
                })
            }
            </div>
            </>
        }
    </div>
}

const isSelectedID = (id, selectedIDs) => {
    if (!id || !selectedIDs) {
        return false;
    }
    if (id && selectedIDs &&
        selectedIDs.includes(id)) {
        return true;
    }
    return false;
}

const colorForSelectedID = (id, scale, value, selectedIDs, defaultColor = "white") => {
    if (id && selectedIDs &&
        selectedIDs.includes(id)) {
        if (scale && value) {
            return colorFromScaleWithValue(scale, value);
        } else {
            return defaultColor;
        }
    }
}

const colorFromScaleWithValue = (scale, value) => {
    let output;
    scale.forEach((scaleItem) => {
        if (value >= scaleItem.lowerBound
            && value < scaleItem.upperBound) {
                output = scaleItem.color
            }
    })
    return output;
}

const downloadSvg = (id) => {
    let chartEl = document.querySelector(`#${id}`)
    // chartEl.setAttribute("width",  600);
    let svgxml = (new XMLSerializer).serializeToString(chartEl)
    let svgurl = "data:image/svg+xml,"+encodeURIComponent(svgxml)
    var dl = document.createElement("a");
        document.body.appendChild(dl); // This line makes it work in Firefox.
        dl.setAttribute("href", svgurl);
        dl.setAttribute("download", `${id}.svg`);
        dl.click();

//     var img = '<img src="'+svgurl+'">';     
//     var canvas = document.querySelector("canvas"),
//     context = canvas.getContext("2d");

//     var image = new Image;
//     image.src = imgsrc;
//     image.onload = function() {
// 	  context.drawImage(image, 0, 0);

// 	  var canvasdata = canvas.toDataURL("image/png");

// 	  var pngimg = '<img src="'+canvasdata+'">'; 
//   	  d3.select("#pngdataurl").html(pngimg);

// 	  var a = document.createElement("a");
// 	  a.download = "sample.png";
// 	  a.href = canvasdata;
// 	  a.click();
//   };

}

const ScrollingScatterplotChart = (props) => {
    const margin = { top: 30, right: 60, bottom: 30, left: 45 };
    
    const y = d3.scaleLinear()
        .domain([0, d3.max(props.data, (d) => {
            return (Math.ceil((d[`${props.yValueField}`]+1)/10))
        })])
        .range([props.height-margin.bottom, margin.top]);

    const x = d3.scaleLinear()
        .domain([0, d3.max(props.data, (d) => {
            return d[`${props.xValueField}`];
        })])
        .range([margin.left+10, props.width - margin.right/2]);
    
    // map svg pixel space to index, for line scrobbler
    const x3 = d3.scaleQuantile()
        .domain([margin.left+10, props.width - margin.right/2])
        .range(props.data.map((v, i) => i));

    
        // .range([margin.left, props.width - margin.right]);
    const svgRef = useRef(null);
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    const getSvgLocation = (e) => {
        let pt = svgRef.current.createSVGPoint();
        pt.x = e.clientX;
        pt.y = e.clientY;
        return pt.matrixTransform(svgRef.current.getScreenCTM().inverse());
    }
    useEffect(() => {
        if (mousePosition.x) {
            const hoveredDataPoint = props.data[x3(mousePosition.x)][props.xValueField];
            if (!props.selectedIDs.includes(hoveredDataPoint)) {
                props.handleToggleSelectedID(hoveredDataPoint);
            }
        } else {
            props.SetSelectedIDs([]);
        }
    }, [mousePosition])

    return (
        <div
            className="scrollable-chart-container"
            ref={props.ud.observe}
            onTouchEnd={e => {
                if (!props.showMiniChart) return;
                setMousePosition({x: e.clientX, y: e.clientY})
                props.setMouseLocation({x: e.clientX, y: e.clientY})
            }}
            onMouseMove={e => {
                if (!props.showMiniChart) return;
                let loc = getSvgLocation(e);
                setMousePosition({x: loc.x, y: loc.y})
                props.setMouseLocation({x: e.clientX, y: e.clientY})
            }}
            onMouseLeave={e => {
                if (!props.showMiniChart) return;
                setMousePosition({x: null, y: null})
                props.setMouseLocation({x: null, y: null})
            }}
        >
        <svg
            id={props.divId} 
            width={props.width}
            height={props.height}
            ref={svgRef}>  
            {/* <defs>
                <pattern id="stripe_pattern" patternUnits="userSpaceOnUse" width="1" height="1" patternTransform="rotate(45)">
                    <line x1="0" y="0" x2="0" y2="1" stroke="#009DDC" strokeWidth="1" />
                </pattern>
            </defs> */}
            {
                props.bands.map((band, index) => {
                    return <g
                            key={index}
                            fill={band.color}>
                            <rect
                                x={margin.left}
                                y={y(band.upperBound)}
                                width={props.width}
                                height={y(band.lowerBound)-y(band.upperBound)}/>
                        </g>
                })
            }
            {
                props.bands.map((band, index) => {
                    return <g
                            key={index}
                            fill={"black"}>
                            <text
                                fontSize={18}
                                x={margin.left}
                                y={y(band.lowerBound)}>
                                {band.title}
                            </text>
                        </g>
                })
            }
            <g transform={`translate(${margin.left},0)`}>
                <text x={-margin.left} y={margin.top-15}>
                    {props.yAxisTitle}
                </text>
                <Axis
                    orient={Orient.left}
                    scale={y}
                    // ticks={[null, "%"]}
                    tickSize={-1 * props.width}
                    tickLineProps={{
                        strokeOpacity: .5,
                        strokeDasharray: '2,2'
                    }}
                    domainPathProps={{
                        strokeOpacity: 0,
                    }}
                    tickFormat={(d) => `${Number.parseFloat(d*100)}%`}
                    tickTextProps={{
                        fontSize: 14,
                    }}
                />
            </g>
            {/* {
                props.width > props.ud.width &&
                <g transform={`translate(${props.width/2},0})`}>
                    <text x={props.width/2} y={margin.top-15}>
                        {props.yAxisTitle}
                    </text>
                    <Axis
                        orient={Orient.right}
                        scale={y}
                        // ticks={[null, "%"]}
                        tickSize={props.width/2}
                        tickLineProps={{
                            strokeOpacity: 0,
                        }}
                        domainPathProps={{
                            strokeOpacity: 0,
                        }}
                        tickFormat={(d) => `${Number.parseFloat(d*100)}%`}
                        tickTextProps={{
                            fontSize: 12,
                        }}
                    />
                </g>
            } */}
            {
                props.width > props.ud.width &&
                <g 
                    transform={`translate(${props.width},0)`}>
                    <text x={props.width-margin.right-margin.left} y={margin.top-15}>
                        {props.yAxisTitle}
                    </text>
                    <Axis
                        orient={Orient.right}
                        scale={y}
                        // ticks={[null, "%"]}
                        tickSize={props.width-margin.left}
                        tickLineProps={{
                            strokeOpacity: 0,
                        }}
                        domainPathProps={{
                            strokeOpacity: 0,
                        }}
                        tickFormat={(d) => `${Number.parseFloat(d*100)}%`}
                        tickTextProps={{
                            fontSize: 14,
                        }}
                    />
                </g>
            }
            {
                // line scrobbler
                props.showMiniChart &&
                mousePosition.x &&
                <line
                    x1={mousePosition.x}
                    x2={mousePosition.x}
                    y1={0}
                    y2={props.height-margin.bottom}
                    strokeWidth={2}
                    stroke={'grey'}
                />
            }
            {
                props.data.map((datum, index) => {
                    return <g
                        cursor={'pointer'}
                        transform={`translate(${x(index)},${y(datum[props.yValueField])})`}
                        onMouseDown={e => {
                            props.handleMouseoverPoint(e.clientX, e.clientY, window.innerWidth, window.innerHeight);
                            props.handleToggleSelectedID(datum[props.xValueField])
                        }}
                        id={datum[props.xValueField]}
                        key={index}
                        opacity={1}
                        fill={props.scale ? 
                            colorFromScaleWithValue(props.scale, datum[props.yValueField]): 
                            props.secondaryScale ? 
                            colorFromScaleWithValue(props.secondaryScale, datum[props.secondaryYValueField]): 
                            'white'}>
                        <circle 
                            stroke={'black'}
                            strokeWidth={props.showMiniChart ? 
                                isSelectedID(datum[props.xValueField], props.selectedIDs) ? 2 : .5
                                : isSelectedID(datum[props.xValueField], props.selectedIDs) ? 3 : 1
                            }
                            color={'black'}
                            r={props.showMiniChart ? 4 : 14}
                        />
                        {
                            !props.showMiniChart &&
                            <text
                                textAnchor="middle"
                                alignmentBaseline="central"
                                // fill="white"
                                fill="black"
                                fontSize={12}
                                >
                                {datum[props.xValueField]}
                            </text>
                        }
                    </g>
                })
            }

            
            <g transform={`translate(0,${props.height-5})`}>
                <text x={margin.left}> ← lower {props.yAxisTitle}</text>
                <text x={props.width-margin.right-150}>higher {props.yAxisTitle} → </text>
            </g>
        </svg>
    </div>
    
    )
}

const ScrollingScatterplotTable = (props) => {
    return (
        <div style={{
            height: props.height,
            width: props.tableWidth > props.ud.width ? 'inherit' : props.tableWidth,
        }} className="scrollable-chart-table">
        {
            props.data &&
            props.data.length > 0 &&
            <table style={{
                tableLayout: 'fixed',
                borderCollapse: 'collapse',
                margin: '0 auto'
            }}>
            <thead style={{
                background: 'lightgrey', 
                color: 'black',
                position: 'sticky',
                top: 0
            }}>
            <tr style={{display: 'block'}}>
            {
                Object.keys(props.data[0]).map((key) => {
                    return <th key={key}
                        style={{
                            padding: '2px',
                            textAlign: 'left',
                            width: props.tableWidth
                        }}>{DISTRICT_LEVEL_FIELD_NAMES[key] ? DISTRICT_LEVEL_FIELD_NAMES[key] : key}</th>
                })
            }
            </tr>
            </thead>
            <tbody style={{
                display: 'block',
                width: 'auto',
                overflow: 'auto',
            }}>
            {
                props.data.map((datum, index) => {
                    return <tr 
                        key={index}
                        style={{
                            background: colorForSelectedID(
                                datum[props.xValueField],
                                props.scale,
                                datum[props.yValueField],
                                props.selectedIDs,
                                'lightgrey'
                            )
                    }}>
                        {
                            Object.keys(datum).map((key) => {
                                return <td key={key}
                                    style={{
                                        padding: '2px',
                                        textAlign: 'left',
                                        minWidth: props.tableWidth
                                    }}
                                    onTouchEnd={e => props.handleToggleSelectedID(datum[props.xValueField])}
                                    onMouseEnter={e => props.handleToggleSelectedID(datum[props.xValueField])}>
                                    {
                                        (Number.isInteger(datum[key]) || 
                                            (key === DISTRICT_LEVEL_FIELDS.REOCK || key === DISTRICT_LEVEL_FIELDS.POLSBY_POPPER)) ? 
                                        datum[key].toPrecision(3)
                                        : `${Number.parseFloat(datum[key]*100).toPrecision(3)}%`
                                    }
                                </td>
                            })
                        }
                    </tr>
                })
            }
            </tbody>
            </table>
        }
        </div>
    )
}

const ScrollingScatterplotSummaryBar = (props) => {
    return (
        <div style={{
            width: '80%',
            display: 'flex',
            margin: '0 auto'
        }}
        >
        {
            props.data &&
            props.data.length > 0 &&
            props.data.map((datum, index) => {
                return <span 
                    key={index}
                    style={{
                        display: 'inline-block',
                        height: 15,
                        flex: '1',
                        background: props.scale ? colorFromScaleWithValue(props.scale, datum[props.yValueField]) : 'white'
                    }} />
            })
        }
        </div>
    )
}

const ScrollingScatterplot = (props) => {
    const [showChart, setShowChart] = useState("chart");
    const [showMiniChart, setShowMiniChart] = useState(true);
    const ud = useDimensions(); 
    const width = showMiniChart ? 425 : ((Math.round(props.data.length/20))*500)+500;
    const tableWidth = ((Math.round(props.data.length/50))*100)+100;
    const height = 350;

    return <>
    <div><strong>{props.chartTitle}</strong></div>
    {/* <ScrollingScatterplotLegend {...props} /> */}
    {
        showChart &&
        showMiniChart ?
        <div>Hover over the chart to see more info</div>
        :
        <div>Select a data point to see more info</div>
    }
    <div>
        <button onClick={() => setShowChart(!showChart)}>
            Show as {showChart ? 'table' : 'chart'}
        </button>
        {
            showChart && 
            <>
            <button onClick={() => setShowMiniChart(!showMiniChart)}>
                Show {showMiniChart ? 'Expanded chart' : 'Minimized chart'}
            </button>
            <button onClick={() => downloadSvg(props.divId)}>
                Download as svg
            </button>
            </>
        }
    </div>
    {props.showSummary && <ScrollingScatterplotSummaryBar {...props} />}
    <>
    {
        showChart ? 
        <ScrollingScatterplotChart
            {...props}
            height={height}
            width={width}
            ud={ud}
            tableWidth={tableWidth}
            showMiniChart={showMiniChart}
        />
        :

        <ScrollingScatterplotTable
            {...props}
            height={height}
            width={width}
            ud={ud}
            tableWidth={100}
        />
    }
    </>
    {
        width > ud.width &&
        <div>
            ← Scroll on the {showChart ? 'chart' : 'table'} to see all data →
        </div>
    }

    </>
}

export default ScrollingScatterplot;