import React from 'react'
import { DISTRICT_LEVEL_FIELDS } from '../../common/class/dashboard-scores';
import { COMPETITIVENESS_COLORS } from '../../common/constants/colors-dashboard';
import RedistrictingDashboardTooltipInfo from '../redistricting-dashboard/RedistrictingDashboardTooltipInfo';
import ScrollingScatterplot from './ScrollingScatterplot';

const CompetitivenessGraph = (props) => {
    // putting this handleMouseover function in each higher-order component 
    // to distinguish between calling location, so that mouse location is reported correctly
    const handleMouseoverPoint = (x, y, windowInnerWidth, windowInnerHeight) => {
        if (window) {
            if (x > windowInnerWidth-250) {
                x = x-250;
            }
            if (y > windowInnerHeight-100) {
                y = y-100;
            }
        }
        props.setMouseLocation({x: x, y: y})
    }

    const handleMouseoutPoint = () => {
        props.setMouseLocation({ x: null, y: null })
    }
    return <>
        <ScrollingScatterplot
            divId={'competitiveness-chart'}
            setMouseLocation={props.setMouseLocation}
            handleMouseoverPoint={handleMouseoverPoint}
            handleMouseoutPoint={handleMouseoutPoint}
            chartTitle={
                <React.Fragment>
                    District by average partisan win percentage
                    <RedistrictingDashboardTooltipInfo.Mark
                        setMouseLocation={props.setMouseLocation}
                        setSelectedAnnotationID={props.setSelectedAnnotationID}
                        annotationName={`Average partisan win percentage`}
                        annotationID={37}
                    />
                </React.Fragment>
            } 
            xValueField={`district`}
            yValueField={`demVoteshare`}
            yAxisTitle={`D vote share`}
            selectedIDs={props.selectedIDs}
            SetSelectedIDs={props.SetSelectedIDs}
            handleToggleSelectedID={props.handleToggleSelectedID}
            data={props.data.getDistrictDataArray(DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE)}
            dataField={`CompetitiveElections`}
            showSummary={true}
            bands={props.customDashboard ? [] : [
                {
                    lowerBound: .465,
                    upperBound: .535,
                    title: `Competitive zone (46.5-53.5% D)`,
                    color: COMPETITIVENESS_COLORS.FOURTY_FIVE_FIFTY_FIVE_PERCENT
                }
            ]}
            scale={props.customDashboard ? 
                [
                    {
                        lowerBound: 0,
                        upperBound: .45,
                        title: `<45% D`,
                        color: COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN
                    },
                    {
                        lowerBound: .45,
                        upperBound: .5,
                        title: `45-50% D`,
                        color: COMPETITIVENESS_COLORS.LEAN_REPUBLICAN
                    },
                    {
                        lowerBound: .50,
                        upperBound: .55,
                        title: `50-55% D`,
                        color: COMPETITIVENESS_COLORS.LEAN_DEMOCRAT
                    },

                    {
                        lowerBound: .55,
                        upperBound: 1,
                        title: `>55% D`,
                        color: COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT
                    },
                ]
                :
                [
                    {
                        lowerBound: 0,
                        upperBound: .465,
                        title: `<46.5% D`,
                        color: COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN
                    },
                    {
                        lowerBound: .465,
                        upperBound: .5,
                        title: `46.5-50% D`,
                        color: COMPETITIVENESS_COLORS.LEAN_REPUBLICAN
                    },
                    {
                        lowerBound: .50,
                        upperBound: .535,
                        title: `50-53.5% D`,
                        color: COMPETITIVENESS_COLORS.LEAN_DEMOCRAT
                    },

                    {
                        lowerBound: .535,
                        upperBound: 1,
                        title: `>53.5% D`,
                        color: COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT
                    },
                ]
            }
        />
    </>
}
export default CompetitivenessGraph;