import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useRef, useEffect, useState } from 'react';
import bbox from '@turf/bbox';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { DISTRICT_LEVEL_FIELDS, DISTRICT_LEVEL_FIELD_FULL_NAMES, AVAILABLE_ELEX, AVAILABLE_ELEX_FULL_NAMES } from '../common/class/dashboard-scores';
import { COMPETITIVENESS_COLORS, VAP_COLORS } from '../common/constants/colors-dashboard';

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_KEY;

export default function MapboxContainer(props) {
    const [selectedLayer, setSelectedLayer] = useState(DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE);
    const [showCounties, setShowCounties] = useState(false);
    const mapContainer = useRef(null);
    const map = useRef(null);
    const popup = useRef(null);
    const tooltip = useRef(null);
    const [lng, setLng] = useState(-70.9);
    const [lat, setLat] = useState(42.35);
    const [zoom, setZoom] = useState(5);

    const handleMouseMove = e => {
        map.current.getCanvas().style.cursor = 'crosshair';

        const feature = e.features[0];
        if (feature.state[props.xValueField] === props.selectedIDs[0]) {
            return
        }
        props.handleToggleSelectedID(feature.state[props.xValueField]);

        let x = e.originalEvent.clientX;
        let y = e.originalEvent.clientY;
        let windowInnerWidth = e.originalEvent.view.window.innerWidth;
        let windowInnerHeight = e.originalEvent.view.window.innerHeight;

        if (x > windowInnerWidth-250) {
            x = x-250;
        }
        if (y > windowInnerHeight-100) {
            y = y-100;
        }
        props.setMouseLocation({x: x, y: y})
    }

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/light-v10',
            center: [lng, lat],
            zoom: zoom,
            pitchWithRotate: false,
            touchPitch: false,
            dragRotate: false,
        });

        map.current.on('load', async () => {
            let layers = map.current.getStyle().layers;
            // Find the index of the first symbol layer in the map style
            let firstSymbolId;
            for (let i = 0; i < layers.length; i++) {
                if (layers[i].type === 'symbol') {
                    firstSymbolId = layers[i].id;
                    break;
                }
            }

            map.current.addControl(new mapboxgl.NavigationControl({
                showCompass: false,
                visualizePitch: false
            }));
            map.current.addSource('districts', {
                type: 'geojson',
                data: props.MapDataURL,
                promoteId: DISTRICT_LEVEL_FIELDS.DISTRICT.toUpperCase()
            });
            map.current.addSource('counties', {
                type: "vector",
                url: "mapbox://openprecincts.us-counties",
            });
            map.current.addSource('counties_label', {
                type: "vector",
                url: "mapbox://openprecincts.3ow64t0q",
            });

            map.current.addLayer(
                {
                    id: 'districts-fill',
                    type: 'fill',
                    source: 'districts'
                },
                firstSymbolId
            );
            map.current.addLayer(
                {
                    id: 'districts-outline',
                    type: 'line',
                    source: 'districts'
                },
            );

            map.current.setPaintProperty('districts-fill', 'fill-color', [
                'case',
                ['>=', ['feature-state', DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE], 0.535],
                COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT,
                ['>=', ['feature-state', DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE], 0.5],
                COMPETITIVENESS_COLORS.LEAN_DEMOCRAT,
                ['>=', ['feature-state', DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE], 0.465],
                COMPETITIVENESS_COLORS.LEAN_REPUBLICAN,
                COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN
            ]);

            map.current.setPaintProperty('districts-outline', 'line-width', [
                'case',
                ['boolean', ['feature-state', 'selected'], true],
                1,
                0.1,
            ]);
            map.current.setPaintProperty('districts-fill', 'fill-opacity', [
                'case',
                ['boolean', ['feature-state', 'selected'], true],
                .9,
                .65,
            ]);
        });

        map.current.on("touchstart", "districts-fill", e => {
            const feature = e.features[0];
            if (!feature.state) {
                return;
            }
            props.handleToggleSelectedID(feature.state[props.xValueField]);
        });
        map.current.on("mousemove", "districts-fill", handleMouseMove);
        map.current.on("mouseout",  e => {
            props.setMouseLocation({x: null, y: null})
            props.SetSelectedIDs([]);
        });
    }, []);

    // handle new map data
    useEffect(() => {
        if (!map.current) return;
        if (props.mapData && props.MapDataURL) {
            if (map.current.getSource('districts')) {
                map.current.getSource('districts').setData(props.MapDataURL)
            }
            const boundingBox = bbox(props.mapData);
            map.current.fitBounds(boundingBox);

        }
    }, [props.mapData, props.MapDataURL]);

    // handle toggling counties layer
    useEffect(() => {
        if (!map.current) return;
        if (!map.current.isStyleLoaded()) return;
        if (showCounties) {
            map.current.addLayer(
                {
                    id: 'counties',
                    type: 'line',
                    source: 'counties',
                    "source-layer": "us_counties",
                    filter: ["==", "STATEFP", props.stateFipsCode]
                },
            );
            map.current.addLayer(
                {
                    id: 'counties_label',
                    type: 'symbol',
                    source: 'counties_label',
                    "source-layer": "us_county_labels-1d656t",
                    paint: {
                        "text-halo-width": 2,
                        "text-halo-color": "rgba(255, 255, 255, 0.7)",
                    },
                    layout: {
                        "text-field": "{NAME}",
                        "text-size": 11,
                    },
                    filter: ["==", "STATEFP", props.stateFipsCode]
                },
            );
        }
        else {
            map.current.removeLayer('counties');
            map.current.removeLayer('counties_label');
        }
    }, [showCounties])

    // handle new score data
    useEffect(() => {
        const setFeatureStateFromData = async (data, debugCtx = null) => {
            Object.keys(data).forEach((key, index) => {
                // TODO - make this configurable?
                // const featurestateValue = {
                //     'color': data[key][DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE] > .5 ? 'blue' : 'red'
                // }

                // HACK - parseInt(x, 10) drops leading zeroes in the feature id (the value in promoteId )
                // e.g. - 01 => 1
                const featureId = `${parseInt(data[key].district, 10)}`;
                const featureStateValue = { ...data[key], selected: false };
                map.current.setFeatureState(
                    { id: featureId, source: 'districts'},
                    featureStateValue
                );
            });
        }

        if (!map.current) return;
        map.current.on('sourcedata', e => {
            if (e.isSourceLoaded && map.current.isStyleLoaded()) {
                setFeatureStateFromData(props.data, 'useEffect [props.data]\nisSourceLoaded && isStyleLoaded');
            }
        })
    }, [props.data])

    // handle new selected district
    useEffect(() => {
        if (!map.current.isStyleLoaded()) return;
        if (props.selectedIDs) {
            Object.keys(props.data).forEach((key) => {
                // HACK - parseInt(x, 10) drops leading zeroes in the feature id (the value in promoteId )
                // e.g. - 01 => 1
                const featureId = `${parseInt(props.data[key].district, 10)}`;
                const newFeatureState = {
                    ...props.data[key],
                    // intentionally permissive with the == below, need to make sure string/number IDs work
                    // TODO - change that ^
                    selected: props.selectedIDs.findIndex(el => el == featureId) >= 0
                };
                map.current.setFeatureState(
                    { id: featureId, source: 'districts'},
                    newFeatureState
                );
            });
        }
    }, [
        // TODO - make sure we need props.data in this case => it _should_ be that all data is loaded on the existing features
        props.data,
        props.selectedIDs
    ]);

    // handle new tooltip
    useEffect(() => {
        if (!map.current) return;
        if (!map.current.isStyleLoaded()) return;

        if (selectedLayer === DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE) {
            map.current.setPaintProperty('districts-fill', 'fill-color', [
                'case',
                ['>=', ['feature-state', DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE], 0.535],
                COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT,
                ['>=', ['feature-state', DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE], 0.5],
                COMPETITIVENESS_COLORS.LEAN_DEMOCRAT,
                ['>=', ['feature-state', DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE], 0.465],
                COMPETITIVENESS_COLORS.LEAN_REPUBLICAN,
                COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN
            ]);
        } else if ([
            DISTRICT_LEVEL_FIELDS.AVAP,
            DISTRICT_LEVEL_FIELDS.BVAP,
            DISTRICT_LEVEL_FIELDS.HVAP,
            DISTRICT_LEVEL_FIELDS.MVAP,
            DISTRICT_LEVEL_FIELDS.NVAP,
            DISTRICT_LEVEL_FIELDS.PVAP,
        ].includes(selectedLayer)) {
            map.current.setPaintProperty('districts-fill', 'fill-color', [
                'case',
                ['>=', ['feature-state', selectedLayer], 0.50],
                VAP_COLORS.FIFTY_HUNDRED_PERCENT,
                ['>=', ['feature-state', selectedLayer], 0.40],
                VAP_COLORS.FOURTY_FIFTY_PERCENT,
                ['>=', ['feature-state', selectedLayer], 0.30],
                VAP_COLORS.THIRTY_FOURTY_PERCENT,
                VAP_COLORS.ZERO_THIRTY_PERCENT,
            ]);
        } else if (Object.keys(AVAILABLE_ELEX).includes(selectedLayer)) {
            map.current.setPaintProperty('districts-fill', 'fill-color', [
                'case',
                ['>=', ['feature-state', selectedLayer], 0.535],
                COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT,
                ['>=', ['feature-state', selectedLayer], 0.5],
                COMPETITIVENESS_COLORS.LEAN_DEMOCRAT,
                ['>=', ['feature-state', selectedLayer], 0.465],
                COMPETITIVENESS_COLORS.LEAN_REPUBLICAN,
                COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN
            ]);
        }
    }, [selectedLayer])

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

      return (
        <div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                <span style={{flex: 1}}>
                    <label style={{ display: "block", width: "100%", textAlign: "center" }}>Map color scale: </label>
                    <select
                        style={{
                            textOverflow: 'ellipsis',
                            width: '100%'
                        }}
                        onChange={(e) => {
                            let val = e.target.value;
                            if (val === DISTRICT_LEVEL_FIELDS.PARTISAN_VOTE_SHARE) {
                                val = DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE
                            }
                            setSelectedLayer(val)
                        }}>
                        {
                            Object.values(Object.assign(DISTRICT_LEVEL_FIELDS,
                                props.customDashboard ? AVAILABLE_ELEX : {})
                            ).map((field) => {
                                if (field === DISTRICT_LEVEL_FIELDS.DISTRICT) return;
                                if (field === DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE) return;
                                return <option
                                    key={field}
                                    value={field}
                                    defaultChecked={field === DISTRICT_LEVEL_FIELDS.PARTISAN_VOTE_SHARE}>
                                    {DISTRICT_LEVEL_FIELD_FULL_NAMES[field] ? DISTRICT_LEVEL_FIELD_FULL_NAMES[field]
                                        : AVAILABLE_ELEX_FULL_NAMES[field] ? AVAILABLE_ELEX_FULL_NAMES[field] : field }
                                </option>
                            })
                        }
                    </select>
                </span>
                <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1
                }}>
                {
                    selectedLayer.includes("vap") ?
                    <>
                    <span>{'<=30%'}</span>
                    {
                        [
                            VAP_COLORS.ZERO_THIRTY_PERCENT,
                            VAP_COLORS.THIRTY_FOURTY_PERCENT,
                            VAP_COLORS.FOURTY_FIFTY_PERCENT,
                            VAP_COLORS.FIFTY_HUNDRED_PERCENT,
                        ].map((color) => {
                            return <span
                                style={{
                                    display: `inline-block`,
                                    height: 20,
                                    width: 20,
                                    backgroundColor: color,
                                }}
                            ></span>
                        })
                    }
                    <span>{'>=50%'}</span>
                    </>
                    :
                    <>
                    <span>R</span>
                    {[
                        COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN,
                        COMPETITIVENESS_COLORS.LEAN_REPUBLICAN,
                        COMPETITIVENESS_COLORS.LEAN_DEMOCRAT,
                        COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT,
                    ].map((color) => {
                        return <span
                            key={color}
                            style={{
                                display: `inline-block`,
                                height: 20,
                                width: 20,
                                backgroundColor: color,
                            }}
                        ></span>
                    })}
                    <span>D</span>
                    </>
                }
                </span>
            </div>
            <div
                style={{
                    height: 450
                }}
                ref={mapContainer}
                className="map-container"
            />
            <button style={{cursor: "pointer", margin: "0 auto", display: "flex"}} onClick={() => setShowCounties(!showCounties)}>{showCounties ? 'Hide' : 'Show'} counties</button>
        </div>
      );
}