import DashboardScores from './dashboard-scores';
import DashboardThresholds from './dashboard-thresholds';
import DashboardReportCard from './dashboard-report-card';
import { isEmpty } from "lodash";

// based on https://github.com/PrincetonUniversity/EIL-Dashboard/blob/master/NC/NC%20Report%20Cards/Report%20Cards/NC%20Cong19Dist%20score.json 
export default class DashboardResults {
    constructor(jsonObj) {
        this.plan = jsonObj['plan'] ? new DashboardScores(jsonObj['plan']) : null;
        this.thresholds = jsonObj['thresholds'] && !isEmpty(jsonObj['thresholds']) ? new DashboardThresholds(jsonObj['thresholds'], this.plan.numDists, this.plan.ActualWins) : null;
        this.reportCard = jsonObj['report_card'] && !isEmpty(jsonObj['report_card']) ? new DashboardReportCard(jsonObj['report_card'], this.plan.state, this.plan.planName) : null;
    }
}