import React, { useEffect, useState } from 'react'
import { DISTRICT_LEVEL_FIELDS, DISTRICT_LEVEL_FIELD_FULL_NAMES } from '../../common/class/dashboard-scores';
import { VAP_COLORS, COMPETITIVENESS_COLORS } from '../../common/constants/colors-dashboard';
import RedistrictingDashboardTooltipInfo from '../redistricting-dashboard/RedistrictingDashboardTooltipInfo';
import ScrollingScatterplot from './ScrollingScatterplot';

const VAPGraph = (props) => {
    const [availableVAPs, SetAvailableVAPs] = useState([]);
    useEffect(() => {
        let VAPS = [];
        if (props.data.BVAPDistricts) {VAPS.push(DISTRICT_LEVEL_FIELDS.BVAP)}
        if (props.data.HVAPDistricts) {VAPS.push(DISTRICT_LEVEL_FIELDS.HVAP)}
        if (props.data.AVAPDistricts) {VAPS.push(DISTRICT_LEVEL_FIELDS.AVAP)}
        if (props.data.NVAPDistricts) {VAPS.push(DISTRICT_LEVEL_FIELDS.NVAP)}
        if (props.data.PVAPDistricts) {VAPS.push(DISTRICT_LEVEL_FIELDS.PVAP)}
        if (props.data.MVAPDistricts) {VAPS.push(DISTRICT_LEVEL_FIELDS.MVAP)}
        SetAvailableVAPs(VAPS);
    }, [props.data]);
    
    // putting this handleMouseover function in each higher-order component 
    // to distinguish between calling location, so that mouse location is reported correctly
    const handleMouseoverPoint = (x, y, windowInnerWidth, windowInnerHeight) => {
        if (window) {
            if (x > windowInnerWidth-250) {
                x = x-250;
            }
            if (y > windowInnerHeight-100) {
                y = y-100;
            }
        }
        props.setMouseLocation({x: x, y: y})
    }

    const handleMouseoutPoint = () => {
        props.setMouseLocation({ x: null, y: null })
    }
    return <>
        <ScrollingScatterplot
            divId={'vap-chart'}
            setMouseLocation={props.setMouseLocation}
            handleMouseoverPoint={handleMouseoverPoint}
            handleMouseoutPoint={handleMouseoutPoint}
            chartTitle={
                <React.Fragment>
                    District by {props.selectedVAP ?
                    <select
                        onChange={(e) => props.SetSelectedVAP(e.target.value)}
                        value={props.selectedVAP}
                    >
                    {
                        availableVAPs.map((vap) => {
                            return <option
                                    id={vap}
                                    key={vap}
                                    value={vap}>
                                        {/* {vap.toLocaleUpperCase()} */}
                                        {DISTRICT_LEVEL_FIELD_FULL_NAMES[DISTRICT_LEVEL_FIELDS[vap.toLocaleUpperCase()]]}
                                        {/* {DISTRICT_LEVEL_FIELD_FULL_NAMES[DISTRICT_LEVEL_FIELDS[props.selectedVAP.toLocaleUpperCase()]]} */}
                                </option>
                            })
                        }
                    </select>
                    : '[select a VAP]'}
                    <RedistrictingDashboardTooltipInfo.Mark
                        setMouseLocation={props.setMouseLocation}
                        setSelectedAnnotationID={props.setSelectedAnnotationID}
                        annotationName={`District by VAP`}
                        annotationID={52}
                    />
                </React.Fragment>
            } 
            xValueField={`district`}
            yValueField={`${props.selectedVAP}`}
            yAxisTitle={`${props.selectedVAP.toLocaleUpperCase()}`}
            selectedIDs={props.selectedIDs}
            SetSelectedIDs={props.SetSelectedIDs}
            handleToggleSelectedID={props.handleToggleSelectedID}
            data={props.data.getDistrictDataArray(`${props.selectedVAP}`)}
            dataField={`${props.selectedVAP}Districts`}
            bands={[
                {
                    lowerBound: .30,
                    upperBound: .40,
                    title: `30-40% ${props.selectedVAP.toLocaleUpperCase()}`,
                    color: VAP_COLORS.THIRTY_FOURTY_PERCENT
                },
                {
                    lowerBound: .40,
                    upperBound: .50,
                    title: `40-50% ${props.selectedVAP.toLocaleUpperCase()}`,
                    color: VAP_COLORS.FOURTY_FIFTY_PERCENT
                },
                {
                    lowerBound: .50,
                    upperBound: 1,
                    title: `>50% ${props.selectedVAP.toLocaleUpperCase()}`,
                    color: VAP_COLORS.FIFTY_HUNDRED_PERCENT
                },
            ]}
            secondaryData={props.data.getDistrictDataArray(DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE)}
            secondaryDataField={`CompetitiveElections`}
            secondaryYValueField={`demVoteshare`}
            secondaryScale={[
                {
                    lowerBound: 0,
                    upperBound: .465,
                    title: `<46.5% D`,
                    color: COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN
                },
                {
                    lowerBound: .465,
                    upperBound: .5,
                    title: `46.5-50% D`,
                    color: COMPETITIVENESS_COLORS.LEAN_REPUBLICAN
                },
                {
                    lowerBound: .50,
                    upperBound: .535,
                    title: `50-53.5% D`,
                    color: COMPETITIVENESS_COLORS.LEAN_DEMOCRAT
                },

                {
                    lowerBound: .535,
                    upperBound: 1,
                    title: `>53.5% D`,
                    color: COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT
                },
            ]}
        />
    </>
}
export default VAPGraph;