import React from "react";
import { Link } from "gatsby";

import "../../styles/redistricting-dashboard-homepage.css";
import eilLogoImage from "../../img/eil.png";
import repusLogoImage from "../../img/repus-logo.svg";
import RedistrictingDashboardMapv2 from "../maps/RedistrictingDashboardMapv2";

const RedistrictingDashboardHomeTableRow = ({ planObj }) => {
  return <li>
    <Link to={`?planId=${planObj.recordId}`}>
      {planObj.data['DashboardPlanName']}
    </Link>
  </li>
}

const RedistrictingDashboardHome = (props) => {
    return (
        <div
          className={"redistricting-dashboard-homepage"}>
            <RedistrictingDashboardMapv2 />
            <h3>Redistricting Season is Open</h3>
            <p>
            Every 10 years, U.S. states redraw their congressional and state legislative maps to account for changes in population. 
            In many states, the politicians who control this process draw district lines in a way that maximizes their party’s partisan advantage and makes it effectively impossible for their opponents to win power.
            </p>
            <p>
            This practice is called gerrymandering.
            </p>
            <h3>Visual tool with powerful analytics</h3>
            <p>
            In response to the 2021 redistricting cycle, the Gerrymandering Project developed this report card as a tool to help the public identify gerrymandered maps. The Redistricting Report Card uses a powerful and unique set of analytics to grade each state’s newly-drawn maps during the redistricting process.
            </p>
            <p>
              Select a map from the dropdown above to view its report card, 
              or select a state to view report cards for maps as they become available.
            </p>

            <h3>Holistic and Interpretable Scoring System</h3>
            <p>
            In addition to scoring maps on competitiveness and geographic features, the Redistricting Report Card uses a cutting-edge algorithm to provide unprecedented analysis of a map's partisan fairness. Along with grading maps in these categories, the tool takes a holistic look at the partisan and minority composition of proposed districting plans.
            </p>

            <h3>Powered by A Million Maps</h3>
            <p>
            Our algorithm generates around one million potential districting plans for each state, providing us with a baseline of what’s possible to draw in a state given its political landscape and redistricting rules. We have also enlisted a collection of 75 advanced mappers with intricate knowledge of specific states and communities to incorporate local geographic expertise in our final grades. 
            </p>
            <p>
            As politicians increasingly turn to powerful computer software to make gerrymandering harder to detect, we believe it’s vital that the public has access to tools that can keep up.
            </p>

            <p>
            Want us to score a map you don't see here? <a href="mailto:redistrict2@princeton.edu">Email us</a> with a link to the digital map files and we'll see what we can do!
            </p>

            <p>
            Data sources: U.S. Census Bureau, state legistlatures, <a href="https://openprecincts.org">OpenPrecincts</a>, <a href="https://mggg.org">MGGG</a>, <a href="https://dataverse.harvard.edu/dataverse/electionscience">VEST</a>, 
            <a href="https://mailchi.mp/0658c35bd252/introducing-the-princeton-mapcorps">Princeton MapCorps</a>, and <a href="https://redistricting.lls.edu/">All About Redistricting</a>
            </p>

            <h2 style={{ textAlign: 'center' }}>
              The Redistricting Report Card is a collaboration between <a href="https://democracy.princeton.edu/">the Princeton Electoral Innovation Lab</a> and <a href="https://represent.us/">RepresentUS</a>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <a 
                  style={{ flex: 1 }}
                  href="https://democracy.princeton.edu/">
                  <img
                    width="100%"
                    src={eilLogoImage} />
                </a>
                <a
                  style={{ flex: 1 }}
                  href="https://represent.us/">
                  <img
                    width="100%"
                    src={repusLogoImage} />
                </a>
              </div>
            </h2>
        </div>
    );
  }
  
  export default RedistrictingDashboardHome;