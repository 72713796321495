import React from 'react'
import * as d3 from 'd3';
import LegendItem from '../LegendItem';

import '../../styles/numberline.css';

const NumberLineLegend = (props) => {
    return <div className="dashboard-numberline-legend">
        <div style={{
            display: 'flex',
            justifyContent: 'space-evenly'
        }}>
        {
            props.scaleValues && 
            Array.isArray(props.scaleValues) && 
            props.scaleValues.length && 
            props.scaleValues.map((item, index) => {
                return <LegendItem
                    key={index}
                    title={`${item.title.toUpperCase()}`}
                    color={item.color}/>
            })
        }
        {
            props.scaleValues && 
            typeof props.scaleValues === "object" && 
            !Array.isArray(props.scaleValues) && 
            <LegendItem
                key={`scaleValues-${JSON.stringify(props.scaleValues)}`}
                title={`${props.scaleValues.title.toUpperCase()}`}
                color={props.scaleValues.color} />
        }
        {
            props.secondaryRange && 
            typeof props.secondaryRange === "object" && 
            !Array.isArray(props.secondaryRange) && 
            <LegendItem
                key={`secondaryRange-${JSON.stringify(props.secondaryRange)}`}
                title={`${props.secondaryRange.title.toUpperCase()}`}
                color={props.secondaryRange.color}
                style={{
                    filter: 'brightness(40%)'
                }}
            />
        }
        </div>
    </div>
}

const NumberLine = (props) => {
    const roundedValue = Number.parseFloat(props.value).toPrecision(5);
    const x = d3.scaleLinear()
        .domain([props.lower, props.upper])
        .range([0, 100]);
    let valueInThisRange = x(roundedValue);
    let colorForValue = null;
    // let railColorGradient = `linear-gradient(90deg, ${props.lowerColor} 0%, ${props.middleColor} 50%, ${props.upperColor} 100%)`;
    let railColorGradient;
    let railLegend = [];
    if (props.scaleValues) {
        if (Array.isArray(props.scaleValues)) {
            // grade scale
            const colorScale = props.scaleValues.map((scaleVal, index) => {
                let output =``;
                let lowerValue = x(scaleVal.lowerBound);
                let upperValue = x(scaleVal.upperBound);
                
                if (props.scaleBin) {
                    lowerValue = (100/props.scaleValues.length)*(index);
                    upperValue = (100/props.scaleValues.length)*(index+1);
                    output += `${scaleVal.color} ${lowerValue}%, `;
                    output += `${scaleVal.color} ${upperValue}% `;
                    if (scaleVal.lowerBound <= roundedValue && roundedValue < scaleVal.upperBound) {
                        const x2 = d3.scaleLinear()
                            .domain([scaleVal.lowerBound, scaleVal.upperBound])
                            .range([0, 100]);
                        valueInThisRange = lowerValue + (upperValue-lowerValue)*(x2(roundedValue)/100);
                        colorForValue = scaleVal.color;
                    }
                } else {
                    output += `${scaleVal.color} ${lowerValue}%, `;
                    output += `${scaleVal.color} ${upperValue}% `;
                    if (scaleVal.lowerBound <= roundedValue && roundedValue < scaleVal.upperBound) {
                        colorForValue = scaleVal.color;
                    }
                }
                // add legend
                railLegend.push({
                    title: scaleVal.title,
                    left: (lowerValue+upperValue)/2
                });
                return output;
            });
            railColorGradient = `linear-gradient(to right, ${colorScale.join()})`;
        } else {
            // pass/fail range
            const lowerValue = x(props.scaleValues.lowerBound)
            const upperValue = x(props.scaleValues.upperBound)
            // add legend
            railLegend.push({
                title: props.scaleValues.title,
                left: (lowerValue+upperValue)/2
            });
            if (props.scaleValues.lowerBound <= roundedValue && roundedValue < props.scaleValues.upperBound) {
                colorForValue = props.scaleValues.color;
            }
            railColorGradient = `linear-gradient(to right,
                grey ${lowerValue}%,
                ${props.scaleValues.color} ${lowerValue}%,
                ${props.scaleValues.color} ${upperValue}%,
                grey ${upperValue}%`;
        } 
    }
    let lowerSecondaryRangeValue, upperSecondaryRangeValue;
    if (props.secondaryRange) {
        lowerSecondaryRangeValue = x(props.secondaryRange.lowerBound)
        upperSecondaryRangeValue = x(props.secondaryRange.upperBound)
    }

    return <div className="dashboard-numberline">
        {/* title */}
        <div className="dashboard-numberline-title">
            <strong>{props.title}</strong>
        </div>

        {/* line */}
        <div
            className="dashboard-numberline-slider"
            title={`${props.value}`}
            role="slider"
            aria-valuenow={`${props.value}`}
            aria-valuemin={`${props.lower}`}
            aria-valuemax={`${props.upper}`}>
            {/* secondary range */}
            {
                props.secondaryRange &&
                <>
                <div
                    style={{
                        left: `${lowerSecondaryRangeValue}%`,
                        width: `${upperSecondaryRangeValue-lowerSecondaryRangeValue}%`,
                        borderColor: `${props.secondaryRange.color}`,
                        filter: `brightness(40%)`
                    }}
                    className="dashboard-numberline-secondary-range"
                ></div>
                </>
            }
            {/* lower/upper bounds */}
            <div className="dashboard-numberline-values">
                {
                    props.showMin &&
                    <span
                        className="dashboard-numberline-rail-legend"
                        style={{
                            left: `-7%`
                        }}
                    >
                        {props.lowerLabel && props.lowerLabel}
                        {props.lowerLabel && ': '}
                        {props.lower}
                    </span>
                }
                {
                    props.showMax && 
                    <span
                        className="dashboard-numberline-rail-legend"
                        style={{
                            right: `-10%`
                        }}
                    >
                        {props.upperLabel && props.upperLabel}
                        {props.upperLabel && ': '}
                        {props.upper}
                    </span>
                }
            </div>

            {/* rail */}
            <div className="dashboard-numberline-rail" style={{
                background: `${props.showScaleGradient && railColorGradient ? railColorGradient : `lightgrey`}`
            }} />
            {/* legend-on-rail */}
            {
                railLegend.map((railLegendItem, index) => {
                    return <div
                        key={index}
                        className="dashboard-numberline-rail-legend"
                        style={{
                            left: `${railLegendItem.left}%`
                        }}
                    >
                        {railLegendItem.title}
                    </div>
                })
            }
            {/* tick marks */}
            {
                props.showTickmarks && 
                x.ticks(props.numTickmarks).map((tick) => {
                    return <div
                        style={{
                            left: `${x(tick)}%`
                        }}
                        className="dashboard-numberline-tickmark"
                    >
                    <span className="dashboard-numberline-tickmark-value">{tick}</span>
                    </div>
                })
            }
            {/* midpoint line */}
            {
                props.showMidpointLine &&
                <div
                    style={{
                        left: `50%`
                    }}
                    className="dashboard-numberline-tickmark"
                />
            }
            {/* indicator */}
            <div className="dashboard-numberline-indicator"
                style={{
                    left: `${valueInThisRange}%`,
                    textAlign: 'center',
                    borderTopColor: colorForValue ? colorForValue : 'red'
                }}
            >
                {/* value */}
                <div
                    className="dashboard-numberline-indicator-value">
                    {props.value && props.value.toFixed(props.decimalPlace)} {props.valueLabel && props.valueLabel}
                </div>
            </div>
        </div>
    </div>
}

export default NumberLine;