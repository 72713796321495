import React, { useState } from 'react'
// import { DISTRICT_LEVEL_FIELDS } from '../../common/class/dashboard-scores';
import { COMPETITIVENESS_COLORS, REPORT_CARD_COLORS } from '../../common/constants/colors-dashboard';
import LegendItem from '../LegendItem';
import '../../styles/reportcard.css';

export const getReportCardScoreColor = (value) => {
    switch (value && value.toUpperCase()) {
        case "A":
            return REPORT_CARD_COLORS.A;
        case "B":
            return REPORT_CARD_COLORS.B;
        case "C":
            return REPORT_CARD_COLORS.C;
        case "D":
            return REPORT_CARD_COLORS.D;
        case "F":
            return REPORT_CARD_COLORS.F;
        case "PASS":
            return REPORT_CARD_COLORS.PASS;
        case "FAIL":
            return REPORT_CARD_COLORS.FAIL;
        case "DEM":
            return COMPETITIVENESS_COLORS.LIKELY_DEMOCRAT;
        case "REP":
            return COMPETITIVENESS_COLORS.LIKELY_REPUBLICAN
        default:
            return null;
    }
}

const ReportCardLegend = () => {
    return <div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-evenly'
        }}>
        {
            Object.keys(REPORT_CARD_COLORS).map((grade, index) => {
                return <LegendItem
                key={index}
                title={grade.toUpperCase()}
                color={REPORT_CARD_COLORS[grade]}/>
            })
        }
        </div>
    </div>
}

const ReportCard = ({ reportCard }) => {
    return <div style={{ position: 'relative' }}>
    <div className={`report-card ${!reportCard ? `empty` : ``}`}>
        {
        <>
        <div className={`report-card-grid`}>
            <div className={"grid-score grid-score-top"}>
                <div className='grid-score-header'>
                    <strong>Partisan Fairness</strong>
                    <div className="score-line"></div>
                    <div
                        className={"grid-score-value bold"}
                        style={{
                            // color: getReportCardScoreColor(reportCard ? reportCard.partisanScore : 'grey')
                        }}>
                        {reportCard ? reportCard.partisanScore : 'N/A'}
                    </div>
                    {/* <div>
                        <span>Partisan advantage: </span>
                        <span style={{
                            fontWeight: 'bold',
                            color: getReportCardScoreColor(reportCard ? reportCard.partisanAdvantage : 'grey')
                        }}>
                            {reportCard && reportCard.partisanAdvantage ? ` ${reportCard.getPartisanAdvantageWithFullPartyName()}` : ' N/A'}
                        </span>
                    </div> */}
                </div>
                <div style={{ display: "flex", gap: "13px" , alignItems: "center" }}>
                    {
                      reportCard && reportCard.partisanAdvantage &&
                      <span className='score-circle' style={{
                          background: getReportCardScoreColor(reportCard.partisanAdvantage)
                      }}>
                    </span>
                    }
                    <p>
                        {reportCard && reportCard.getPartisanGradeDescription()}
                    </p>
                </div>
            </div>
            <div className={"grid-score"}>
                <div className='grid-score-header'>
                    <strong>Competitiveness</strong>
                    <div className="score-line"></div>
                    <div
                        className={"grid-score-value"}
                        style={{
                            // color: getReportCardScoreColor(reportCard ? reportCard.competitiveness : 'grey')
                        }}>
                        {reportCard ? reportCard.competitiveness : 'N/A'}
                    </div>
                </div>
                <p>
                    {reportCard && reportCard.getCompetitivenessGradeDescription()}
                </p>
            </div>
            <div className={"grid-score"}>
                <div className='grid-score-header'>
                    <strong>Geographic Features</strong>
                    <div className="score-line"></div>
                    <div
                        className={"grid-score-value"}
                        style={{
                            // color: getReportCardScoreColor(reportCard ? reportCard.geographic : 'grey')
                        }}>
                        {reportCard ? reportCard.geographic : 'N/A'}
                    </div>
                </div>
                <p>
                    {reportCard && reportCard.getGeographicGradeDescription()}
                </p>
            </div>
            
            {/* {reportCard && reportCard.getMinorityGradeDescription() &&
                <div className={"grid-score"}>
                    <div>
                        <strong>Minority Composition</strong>
                    </div>
                    <p>
                        {reportCard && reportCard.getMinorityGradeDescription()}
                    </p>
                </div>
            } */}
        </div>
        <div className='score-legend'>
            <strong>A:</strong> good for category; <strong>B:</strong> better than average with some bias; <strong>C:</strong> average; <strong>D/F:</strong> poor.
        </div>
        </>
        }   
    </div>
    {
        !reportCard &&
        <div className={`empty-info`}>
            {/* <div className="icon">🚫📝</div> */}
            <h2>No report card</h2>
            <h3>Check out metrics for this map below.</h3>
            <div className="icon">⬇️</div>
        </div>
    }
    </div>
}
export default ReportCard;