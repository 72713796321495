import React from "react";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import "../styles/links-list.css";
import {Link} from "gatsby";
import clipImage from "../img/clip.svg";
const LinksList = props => {
  return (
    <div className="links-list">
      {props.title && <h2>{props.title}</h2>}
      <ul className="list">
        {props.links.map((item, index) => {
          const {isExternal, title, href} = item;
          if (!href) return
          return (
            <li key={index}>
              {isExternal &&
                <>
                  <OutboundLink
                    target="_blank"
                    rel='noopener noreferrer'
                    href={href}
                  >
                    {title}
                  </OutboundLink>
                  <div className="external-link-icon">
                    <img src={clipImage} alt=""/>
                    <span>External Link</span>
                  </div>
                </>
              }
              {
                !isExternal &&
                <Link to={href}>{title}</Link>
              }
            </li>
          )
        })}
      </ul>
    </div>
  );
};

export default LinksList;
