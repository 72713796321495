import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const Mark = ({
  setMouseLocation,
  setSelectedAnnotationID,
  annotationID
}) => {
  const handleHoverAnnotation = (e) => {
    e.stopPropagation();
    
    setSelectedAnnotationID(annotationID);
    let x = e.clientX;
    let y = e.clientY;
    if (window) {
        if (x > window.innerWidth-300) {
            x = x-300;
        }
        if (y > window.innerHeight-200) {
            y = y-200;
        }
    }
    setMouseLocation({
      x: x,
      y: y,
    });
  }
  const handleAnnotationMouseLeave = () => {
    setSelectedAnnotationID(null);
    setMouseLocation({
      x: null,
      y: null,
    });
  }
  return (
      <span
        role="text"
        aria-label={`Hover for more info`}
        className="dashboard-tooltip-mark"
        onMouseEnter={(e) => handleHoverAnnotation(e)}
        onTouchEnd={(e) => handleHoverAnnotation(e)}
        onMouseLeave={() => handleAnnotationMouseLeave()}>
        ?
      </span>
  );
}

const RedistrictingDashboardTooltipInfo = ({
    mouseLocation,
    selectedAnnotationID,
    renderInPortal,
    portalDiv,
}) => {
  const [annotationName, setAnnotationName] = useState(null);
  const [annotationText, setAnnotationText] = useState(null);
  const annotationData = useStaticQuery(graphql`
  query dashboardAnnotationsPageQuery {
    allAirtableDashboardAnnotations {
      nodes {
        id
        data {
          AnnotationText {
            id
            childMdx {
              body
            }
          }
          AnnotationName
          AnnotationSection
          AnnotationID
        }
      }
    }
  }
  `);

  useEffect(() => {
    if (!annotationData.allAirtableDashboardAnnotations.nodes) return;
    const selectedAnnotationIDData = annotationData.allAirtableDashboardAnnotations.nodes.filter((annotation) => selectedAnnotationID === annotation.data.AnnotationID); 
    if (selectedAnnotationID && selectedAnnotationIDData) {
      setAnnotationName(selectedAnnotationIDData[0].data.AnnotationName);
      setAnnotationText(selectedAnnotationIDData[0].data.AnnotationText.childMdx.body);
    } else {
      setAnnotationName(null);
      setAnnotationText(null);
    }
  }, [selectedAnnotationID]);

  const tooltipDiv = (
    <div className={`dashboard-tooltip ${!mouseLocation.x && !mouseLocation.y ? `hide` : ``}`} style={{
        display: 'inherit',
        top: mouseLocation.y ? `${mouseLocation.y+10}px` : `-9999px`,
        left: mouseLocation.x ? `${mouseLocation.x-50}px` : `-9999px`,
      }}>
        <strong>{annotationName}</strong>
        {
          annotationText && 
          <MDXRenderer>{annotationText}</MDXRenderer>
        }
      </div>
  )
      
  if (renderInPortal) {
    ReactDOM.createPortal(tooltipDiv, portalDiv);
  } else {
    return tooltipDiv;
  }
}

RedistrictingDashboardTooltipInfo.Mark = Mark;

export default RedistrictingDashboardTooltipInfo;