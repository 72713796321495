import React, {
    useState
} from "react";
  
import '../styles/expandable.css';

// first child => title
// second child => detail
export const ExpandableHeader = ({
    isExpanded,
    isExpandDisabled,
    handleClickHeader,
    children,
    style
}) => {
    const childrenArray = React.Children.toArray(children);

    return <div
        role="text" aria-label={`Tap to ${isExpanded ? `collapse` : `expand`}`}
        onClick={!isExpandDisabled ? handleClickHeader : undefined}
        className={"expandable-header"}
        style={
            { cursor: isExpandDisabled ? '' : 'pointer' },
            {...style}
        }>
        <div className={"expandable-title"}>
            {!isExpandDisabled ? isExpanded ? '▼' : '▶' : ``}
            {childrenArray[0]}
        </div>
        {/* <div className={"expandable-header-spacer"} /> */}
        <div className={"expandable-header-detail"}>
            {childrenArray[1]}
        </div>
    </div>
}

// first child => ExpandableHeader
// second child => any
const Expandable = ({
    ariaLabel,
    children,
    isExpandedDefault,
}) => {
    const [isExpanded, setExpanded] = useState(isExpandedDefault);
    const handleClickHeader = () => {
        setExpanded(isExpanded => !isExpanded)
    }
    const childrenArray = React.Children.toArray(children);
    return (
        <div role="text" aria-label={ariaLabel} className="expandable">
            {React.cloneElement(childrenArray[0], {isExpanded, handleClickHeader})}
            <div className={isExpanded ? 'expandable-children-expanded' : 'expandable-children-collapsed'}>
                {childrenArray[1]}
            </div>
        </div>
    );
  }
  
  export default Expandable;