import React, { useEffect, useState } from "react";
import { globalHistory } from "@reach/router";

import RedistrictingDashboardContainer from "../components/redistricting-dashboard/RedistrictingDashboardContainer";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../styles/styles.css";
import RedistrictingDashboardHome from "../components/redistricting-dashboard/RedistrictingDashboardHome";

const RedistrictingReportCardPage = (context) => {
  const [pageTitle, setPageTitle] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [planUrl, setPlanUrl] = useState(null);
  const [planMapUrl, setPlanMapUrl] = useState(null);
  const [stateAbbrev, setStateAbbrev] = useState(null);
  const [customDashboard, setCustomDashboard] = useState(null);

  useEffect(() => {
    const checkQueryParams = () => {
      /*
        handle URL query params for the page
        - planId
        - planUrl
        - planMapURL
        - stateAbbrev
        - customDashboard
      */
      if (window.location.search === "") {
        setPlanId(null);
      }

      const urlParams = new URLSearchParams(window.location.search);
      urlParams.forEach((value, key) => {
        switch (key) {
          // properly formatted plan score data link passed as url query param
          case 'custom':
            setPlanId(null);
            setCustomDashboard(value);
            break;
          case 'planUrl':
          case 'planURL':
            setPlanId(null);
            setPlanUrl(value);
            break;
          // state abbreviation ex. NJ
          case 'state':
          case 'stateAbbrev':
            setStateAbbrev(value);
            break;
          // airtable record plan id passed as url query param
          case 'planId':
            setPlanId(value);
            setPlanUrl(null);
            break;
          // URL to geojson for the plan
          case 'planMapURL':
          case 'planMapUrl':
            setPlanId(null);
            setPlanMapUrl(value);
            break;
          // URL to geojson for the plan
          case 'title':
            setPageTitle(value);
            break;
          default:
            break;
        }
      });
    }
    checkQueryParams();
    return globalHistory.listen(() => {
      checkQueryParams()
    })
  }, []);
  return (
    <Layout>
      <SEO
        title={`Redistricting Report Card${pageTitle ? ` - ${pageTitle}`: ''}`}
        imagePath={`assets/images/pgp-og-report-card.png`}
        imageWidth={1200}
        imageHeight={630}
      />
        <div className="page">
          {
            planId ?
            <RedistrictingDashboardContainer planId={planId} setPageTitle={setPageTitle} />
            :
            customDashboard || planUrl || planMapUrl ?
            <RedistrictingDashboardContainer
              customDashboard={customDashboard || (planUrl && planMapUrl && stateAbbrev === 'NJ')}
              planUrl={planUrl}
              planMapUrl={planMapUrl}
              setPlanUrl={setPlanUrl}
              setPlanMapUrl={setPlanMapUrl}
              stateAbbrev={stateAbbrev}
              pageTitle={pageTitle}
              setPageTitle={setPageTitle}
            />
            :
            <RedistrictingDashboardHome />
          }
        </div>
    </Layout>
  );
};

export default RedistrictingReportCardPage;