import React from 'react'
import ReactDOM from 'react-dom';
import { AVAILABLE_ELEX, AVAILABLE_ELEX_FULL_NAMES, DISTRICT_LEVEL_FIELDS, DISTRICT_LEVEL_FIELD_NAMES } from '../../common/class/dashboard-scores';

// HACK - 1:1 copy of the below React component as raw HTML to fit Mapbox 
// TODO - use usePortal or similar to render directly from there
export const RedistrictingDashboardTooltipDataHTML = (districtDataObj, selectedValue) => {
  if (selectedValue === DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE) {
    selectedValue = DISTRICT_LEVEL_FIELDS.BVAP;
  }
  const tooltipHTML = `<div class='dashboard-tooltip'>
      <strong>${DISTRICT_LEVEL_FIELD_NAMES[DISTRICT_LEVEL_FIELDS.DISTRICT]} ${districtDataObj[DISTRICT_LEVEL_FIELDS.DISTRICT]}</strong>
      <table>
      <thead>
        <tr>
          <th>${DISTRICT_LEVEL_FIELD_NAMES[DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]}</th>
          <th>${DISTRICT_LEVEL_FIELD_NAMES[DISTRICT_LEVEL_FIELDS.REP_VOTE_SHARE]}</th>
          <th>${DISTRICT_LEVEL_FIELD_NAMES[selectedValue]}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            ${(Number(
              districtDataObj[DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]
            )* 100).toPrecision(4)}%
          </td>
          <td>
            ${(Number(
              1-districtDataObj[DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]
            )* 100).toPrecision(4)}%
          </td>
          <td>
            ${(Number(
              districtDataObj[selectedValue]
            )* 100).toFixed(1)}%
          </td>
        </tr>
      </tbody>
    </table>
  </div>`;
  return tooltipHTML;
}

const RedistrictingDashboardTooltipData = ({
    mouseLocation,
    selectedIDs,
    data,
    renderInPortal,
    portalDiv,
    selectedValue
}) => {
  const tooltipDiv = (
    <div className={`dashboard-tooltip ${!mouseLocation.x && !mouseLocation.y ? `hide` : ``}`} style={{
        display: selectedIDs.length > 0 && selectedIDs[0] ? 'inherit' : 'none',
        top: mouseLocation.y ? `${mouseLocation.y+10}px` : `-9999px`,
        left: mouseLocation.x ? `${mouseLocation.x-50}px` : `-9999px`,
      }}>
        {/* <button onClick={() => SetSelectedIDs([])}>Hide</button> */}
        <strong>
          {DISTRICT_LEVEL_FIELD_NAMES[DISTRICT_LEVEL_FIELDS.DISTRICT]} {selectedIDs[0]}
        </strong>
        <table>
          <thead>
            <tr>
              <th>{DISTRICT_LEVEL_FIELD_NAMES[DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]}</th>
              <th>{DISTRICT_LEVEL_FIELD_NAMES[DISTRICT_LEVEL_FIELDS.REP_VOTE_SHARE]}</th>
              <th>{DISTRICT_LEVEL_FIELD_NAMES[selectedValue] ? DISTRICT_LEVEL_FIELD_NAMES[selectedValue] 
                : AVAILABLE_ELEX_FULL_NAMES[selectedValue] ? AVAILABLE_ELEX_FULL_NAMES[selectedValue] : selectedValue}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {(Number(
                  data.getDistrictDataObject()[selectedIDs[0]][DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]
                )* 100).toPrecision(4)}%
              </td>
              <td>
                {(Number(
                  1-data.getDistrictDataObject()[selectedIDs[0]][DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]
                )* 100).toPrecision(4)}%
              </td>
              <td>
                {(Number(
                  data.getDistrictDataObject()[selectedIDs[0]][selectedValue]
                )* 100).toFixed(1)}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  )
  if (renderInPortal) {
    ReactDOM.createPortal(tooltipDiv, portalDiv);
  } else {
    return tooltipDiv;
  }
}

export default RedistrictingDashboardTooltipData;