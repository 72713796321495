export const DISTRICT_LEVEL_FIELDS = {
    DEM_VOTE_SHARE: 'demVoteshare',
    PARTISAN_VOTE_SHARE: 'partisanVoteshare',
    DISTRICT: 'district',
    BVAP: 'bvap',
    HVAP: 'hvap',
    AVAP: 'avap',
    NVAP: 'nvap',
    PVAP: 'pvap',
    MVAP: 'mvap',
    POLSBY_POPPER: 'Polsby-Popper',
    REOCK: 'Reock'
};

export const DISTRICT_LEVEL_FIELD_NAMES = {
    [DISTRICT_LEVEL_FIELDS.PARTISAN_VOTE_SHARE]: 'Estimated Partisan Vote Share',
    [DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]: 'Estimated Democratic Vote Share',
    [DISTRICT_LEVEL_FIELDS.REP_VOTE_SHARE]: 'Estimated Republican Vote Share',
    [DISTRICT_LEVEL_FIELDS.DISTRICT]: 'District',
    [DISTRICT_LEVEL_FIELDS.BVAP]: 'BVAP',
    [DISTRICT_LEVEL_FIELDS.HVAP]: 'HVAP',
    [DISTRICT_LEVEL_FIELDS.AVAP]: 'AVAP',
    [DISTRICT_LEVEL_FIELDS.NVAP]: 'NVAP',
    [DISTRICT_LEVEL_FIELDS.PVAP]: 'PVAP',
    [DISTRICT_LEVEL_FIELDS.MVAP]: 'MVAP',
    [DISTRICT_LEVEL_FIELDS.POLSBY_POPPER]: 'Polsby-Popper',
    [DISTRICT_LEVEL_FIELDS.REOCK]: 'Reock'
};

export const DISTRICT_LEVEL_FIELD_FULL_NAMES = {
    [DISTRICT_LEVEL_FIELDS.PARTISAN_VOTE_SHARE]: 'Estimated Partisan Vote Share',
    [DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]: 'Democratic Vote Share',
    [DISTRICT_LEVEL_FIELDS.REP_VOTE_SHARE]: 'Republican Vote Share',
    [DISTRICT_LEVEL_FIELDS.DISTRICT]: 'District',
    [DISTRICT_LEVEL_FIELDS.BVAP]: 'Black Voting Age Population',
    [DISTRICT_LEVEL_FIELDS.HVAP]: 'Hispanic Voting Age Population',
    [DISTRICT_LEVEL_FIELDS.AVAP]: 'Asian Voting Age Population',
    [DISTRICT_LEVEL_FIELDS.NVAP]: 'American Indian and Alaska Native Voting Age Population',
    [DISTRICT_LEVEL_FIELDS.PVAP]: 'Native Hawaiian and Other Pacific Islander Voting Age Population',
    [DISTRICT_LEVEL_FIELDS.MVAP]: 'Minority Voting Age Population',
    [DISTRICT_LEVEL_FIELDS.POLSBY_POPPER]: 'Polsby-Popper compactness score',
    [DISTRICT_LEVEL_FIELDS.REOCK]: 'Reock compactness score'
};

// TODO - refactor this to be pulled from the data 
// NJ dashboard specific elections
export const AVAILABLE_ELEX = {
    SEN18: 'SEN18',
    SEN20: 'SEN20',
    GOV17: 'GOV17',
    PRES16: 'PRES16',
    PRES20: 'PRES20',
};
export const AVAILABLE_ELEX_FULL_NAMES = {
    SEN18: '2018 Senate (%D)',
    SEN20: '2020 Senate (%D)',
    GOV17: '2017 Gubernatorial (%D)',
    PRES16: '2016 Presidential (%D)',
    PRES20: '2020 Presidential (%D)',
};

// based on https://raw.githubusercontent.com/PrincetonUniversity/EIL-Dashboard/master/NC/NC%20Report%20Cards/Scoring/NC%20Cong19Dist.json
export default class DashboardScores {
    constructor(jsonObj, sampleData) {
        this.state = jsonObj['state'] ? jsonObj['state'] : null;
        this.splitState = jsonObj['splitState'] ? jsonObj['splitState'] : null;
        this.splitPairs = jsonObj['splitPairs'] ? jsonObj['splitPairs'] : null;
        this.planName = jsonObj['planName'] ? jsonObj['planName'] : null;
        this.numDists = jsonObj['numDists'] ? jsonObj['numDists'] : null;
        // geographic
        this.countySplits = jsonObj['countySplits'] ? jsonObj['countySplits'] : jsonObj['countySplits'] === 0 ? 0 : null;
        // TODO - replace this with ?? 
        this.lowerBoundCountySplits = jsonObj['lowerBoundCountySplits'] ? jsonObj['lowerBoundCountySplits'] : jsonObj['lowerBoundCountySplits'] === 0 ? 0 : null;
        this.upperBoundCountySplits = jsonObj['upperBoundCountySplits'] ? jsonObj['upperBoundCountySplits'] : null;
        this.minPolsbyPopper = jsonObj['minPolsby-Popper'] ? jsonObj['minPolsby-Popper'] : null;
        this.avgPolsbyPopper = jsonObj['avgPolsby-Popper'] ? jsonObj['avgPolsby-Popper'] : null;
        this.allPolsbyPopper = jsonObj['allPolsby-Popper'] ? jsonObj['allPolsby-Popper'] : null;
        this.minReock = jsonObj['minReock'] ? jsonObj['minReock'] : null;
        this.avgReock = jsonObj['avgReock'] ? jsonObj['avgReock'] : null;
        this.allReock = jsonObj['allReock'] ? jsonObj['allReock'] : null;
        this.cutEdgeCompactness = jsonObj['cutEdgeCompactness'] ? jsonObj['cutEdgeCompactness'] : null;
        this.totalEdges = jsonObj['TotalEdges'] ? jsonObj['TotalEdges'] : null;
        
        // racial 
        this.BVAPDistricts = jsonObj['BVAPDistricts'] ? jsonObj['BVAPDistricts'] : null;
        this.HVAPDistricts = jsonObj['HVAPDistricts'] ? jsonObj['HVAPDistricts'] : null;
        this.AVAPDistricts = jsonObj['AVAPDistricts'] ? jsonObj['AVAPDistricts'] : null;
        this.NVAPDistricts = jsonObj['NVAPDistricts'] ? jsonObj['NVAPDistricts'] : null;
        this.PVAPDistricts = jsonObj['PVAPDistricts'] ? jsonObj['PVAPDistricts'] : null;
        this.MVAPDistricts = jsonObj['MVAPDistricts'] ? jsonObj['MVAPDistricts'] : null;
        this.RacialBreakdown = jsonObj['RacialBreakdown'] ? jsonObj['RacialBreakdown'] : null;
        
        // partisan
        this.CompetitiveElections = jsonObj['CompetitiveElections'] ? jsonObj['CompetitiveElections'] : null;
        this.PlanDemVoteshare = jsonObj['DemVoteshare'] ? jsonObj['DemVoteshare'] : null;
        this.avgDemWin = jsonObj['avgDemWin'] ? jsonObj['avgDemWin'] : null;
        this.avgRepWin = jsonObj['avgRepWin'] ? jsonObj['avgRepWin'] : null;
        this.efficiencyGap = jsonObj['EG'] ? jsonObj['EG'] : null;
        this.partisanBias = jsonObj['partisanBias'] ? jsonObj['partisanBias'] : null;
        this.meanMedian = jsonObj['meanMedian'] ? jsonObj['meanMedian'] : null;
        
        // ensemble
        this.CompetitiveDistribution = jsonObj['CompetitiveDistribution'] ? jsonObj['CompetitiveDistribution'] : null;
        this.WinsDistribution = jsonObj['WinsDistribution'] ? jsonObj['WinsDistribution'] : null;
        this.ActualWins = jsonObj['ActualWins'] ? jsonObj['ActualWins'] : jsonObj['ActualWins'] === 0 ? 0 : null;
        this.FractionalWins = jsonObj['FractionalWins'] ? jsonObj['FractionalWins'] : jsonObj['FractionalWins'] === 0 ? 0 : null;
        // todo - replace this with ?? 
        this.ActualCompetitive = jsonObj['ActualCompetitive'] ? jsonObj['ActualCompetitive'] : jsonObj['ActualCompetitive'] === 0 ? 0 : null;
        // this.PvalCompetitive = jsonObj['PvalCompetitive'] ? jsonObj['PvalCompetitive'] : null;
        // this.PvalWins = jsonObj['PvalWins'] ? jsonObj['PvalWins'] : null;
        this.ActualDpval = jsonObj['ElectAvgDpval'] ? jsonObj['ElectAvgDpval'] : null;
        this.ActualRpval = jsonObj['ElectAvgRpval'] ? jsonObj['ElectAvgRpval'] : null;
        this.ActualComppval = jsonObj['ElectAvgComppval'] ? jsonObj['ElectAvgComppval'] : null;
        this.ActualFracDpval = jsonObj['ElectAvgFracDpval'] ? jsonObj['ElectAvgFracDpval'] : null;
        this.ActualFracRpval = jsonObj['ElectAvgFracRpval'] ? jsonObj['ElectAvgFracRpval'] : null;

        Object.keys(AVAILABLE_ELEX).forEach(election => {
            this[election] = jsonObj[election] ? jsonObj[election] : null;

            this[`${election}wins`] = jsonObj[`${election}wins`] ? jsonObj[`${election}wins`] : null;
            this[`${election}frac`] = jsonObj[`${election}frac`] ? jsonObj[`${election}frac`] : null;
            this[`${election}comp`] = jsonObj[`${election}comp`] ? jsonObj[`${election}comp`] : null;
            this[`${election}Dpval`] = jsonObj[`${election}Dpval`] ? jsonObj[`${election}Dpval`] : null;
            this[`${election}Rpval`] = jsonObj[`${election}Rpval`] ? jsonObj[`${election}Rpval`] : null;
            this[`${election}Comppval`] = jsonObj[`${election}Comppval`] ? jsonObj[`${election}Comppval`] : null;
            this[`${election}FracDpval`] = jsonObj[`${election}FracDpval`] ? jsonObj[`${election}FracDpval`] : null;
            this[`${election}FracRpval`] = jsonObj[`${election}FracRpval`] ? jsonObj[`${election}FracRpval`] : null;
        })

        if (sampleData) {
            this.generateRandomData(Math.round((Math.random()+1)*10))
        }
    }

    
    generateRandomData = (size) => {
        for (let i=0; i<size; i++) {
            let randomDVoteShare = Math.random();
            while (randomDVoteShare < .25 || randomDVoteShare > .65) {
                randomDVoteShare = Math.random();
            }
            if (randomDVoteShare >= .45 && randomDVoteShare < .55) {
                this.ActualCompetitive = this.ActualCompetitive + 1; 
            }
            if (!this.CompetitiveElections) {
                this.CompetitiveElections = {};
            }
            this.CompetitiveElections[`${i}`] = randomDVoteShare;
            
            const randomBVAP = Math.random();
            if (!this.BVAPDistricts) {
                this.BVAPDistricts = {};
            }
            this.BVAPDistricts[`${i}`] = randomBVAP;

            const randomHVAP = Math.random();
            if (!this.HVAPDistricts) {
                this.HVAPDistricts = {};
            }
            this.HVAPDistricts[`${i}`] = randomHVAP;
        }
    }

    getDistrictDataArray(sortField=DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE) {
        if (!this.CompetitiveElections) {
            return [];
        }
        return Object.keys(this.CompetitiveElections).map((district) => {
            let output = {
                [DISTRICT_LEVEL_FIELDS.DISTRICT]: Number.parseInt(district),
                [DISTRICT_LEVEL_FIELDS.DEM_VOTE_SHARE]: this.CompetitiveElections[district],
            }
            if (this.BVAPDistricts) {
                output[DISTRICT_LEVEL_FIELDS.BVAP] = this.BVAPDistricts[district];
            }
            if (this.HVAPDistricts) {
                output[DISTRICT_LEVEL_FIELDS.HVAP] = this.HVAPDistricts[district];
            }
            if (this.AVAPDistricts) {
                output[DISTRICT_LEVEL_FIELDS.AVAP] = this.AVAPDistricts[district];
            }
            if (this.NVAPDistricts) {
                output[DISTRICT_LEVEL_FIELDS.NVAP] = this.NVAPDistricts[district];
            }
            if (this.PVAPDistricts) {
                output[DISTRICT_LEVEL_FIELDS.PVAP] = this.PVAPDistricts[district];
            }
            if (this.MVAPDistricts) {
                output[DISTRICT_LEVEL_FIELDS.MVAP] = this.MVAPDistricts[district];
            }
            Object.keys(AVAILABLE_ELEX).forEach(election => {
                if (this[election]) {
                    output[election] = this[election][district];
                }
            });
            if (this.allPolsbyPopper) {
                output[DISTRICT_LEVEL_FIELDS.POLSBY_POPPER] = this.allPolsbyPopper[district];
            }
            if (this.allReock) {
                output[DISTRICT_LEVEL_FIELDS.REOCK] = this.allReock[district];
            }

            return output;
        }).sort((d1, d2) => d1[sortField] > d2[sortField] ? 1 : -1);
    }

    getDistrictDataObject(sortField) {
        const districtDataArray = this.getDistrictDataArray(sortField);
        let output = {};
        districtDataArray.forEach((datum) => {
            output[datum[DISTRICT_LEVEL_FIELDS.DISTRICT]] = datum;
        })
        return output;
    }
}