import React from 'react';
import * as d3 from 'd3';
import { Axis, Orient } from 'd3-axis-for-react';
import useDimensions from "react-cool-dimensions";

import { DISTRICT_LEVEL_FIELDS } from '../../common/class/dashboard-scores';
import { COMPETITIVENESS_COLORS } from '../../common/constants/colors-dashboard';

const winsFreq = [
    0.0,
    0.0,
    4.0,
    937.0,
    28906.0,
    693361.0,
    258296.0,
    18489.0,
    7.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0
];

const compFreq = [
    65085.0,
    257683.0,
    337831.0,
    218837.0,
    94747.0,
    24881.0,
    933.0,
    3.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0
];

const HistoBar = (props) => {
    const data = props.data;
    
    const ud = useDimensions(); 
    const height = props.height || 300;
    const width = ud.width;
    const margin = {
        top: 50,
        secondaryRangeTop: 12,
        bottom: 40,
        left: 50,
        right: 50
    };

    const x = d3.scaleLinear()
        .domain([0, props.numDists])
        .range([0, (width-margin.left-margin.right)]);

    const y = d3.scaleLinear()
        .domain([d3.max(data, (d) => {
            return d;
        }), 0])
        .range([margin.top, height-margin.bottom]);
    
    let bandContainingMapIndex;
    return <div style={{ width: '100%' }} ref={ud.observe}>
        <svg width={width} height={height}>
            {/* grade range bands */}
            <g transform={`translate(${Number.isInteger(props.value) ? margin.left : margin.left+(x(1)/2)}, ${0})`}>
                {
                    props.showBands &&
                    props.bands.map((band, index) => {
                        const rangeWidth = x(band.upperBound)-x(band.lowerBound);
                        if (band.upperBound > props.value && band.lowerBound <= props.value) {
                            bandContainingMapIndex = index;
                        }
                        if (rangeWidth < 0) {
                            return null;
                        }
                        return <React.Fragment key={index}>
                            <rect
                                fill={band.color}
                                x={x(band.lowerBound)}
                                y={height/4}
                                height={height/2}
                                width={rangeWidth}
                            />
                            <text
                                x={x((band.lowerBound)+.1)}
                                y={height/3}>
                                    {band.title}
                            </text>
                            {
                                band.title !== 'A' &&
                                <text
                                    x={x((band.upperBound)-band.upperBound*.05)}
                                    y={height/3}>
                                        {band.title}
                                </text>
                            }
                        </React.Fragment>
                    })
                }
            </g>
            {/* secondary range callout */}
            {
                props.secondaryRange && 
                <g transform={`translate(${margin.left+(x(1)/2)}, ${0})`}>
                    <line
                        fill="grey"
                        stroke="grey"
                        strokeWidth={2}
                        strokeDasharray={"4 2"}
                        x1={x(props.secondaryRange.upperBound)}
                        y1={margin.secondaryRangeTop}
                        x2={Math.floor(x(props.numDists)-margin.right-margin.left)}
                        y2={margin.secondaryRangeTop}
                    />
                    <text
                        x={x(props.numDists)-margin.right-margin.left}
                        alignmentBaseline={"central"}
                        y={margin.secondaryRangeTop}
                        fill={"grey"}
                    >
                        {props.secondaryRangeLabel}
                    </text>
                    <path
                        fill="none"
                        stroke={'black'}
                        strokeWidth="3"
                        d={`M${x(props.secondaryRange.lowerBound)},${margin.top/2} L${x(props.secondaryRange.lowerBound)},${margin.secondaryRangeTop} ${x(props.secondaryRange.upperBound)},${margin.secondaryRangeTop} ${x(props.secondaryRange.upperBound)},${margin.top/2}`} />
                    <polyline
                        fill="none"
                        stroke={'black'}
                        strokeWidth="3"
                        points={`${x(props.secondaryRange.lowerBound)},${margin.top/2} ${x(props.secondaryRange.lowerBound)},${margin.secondaryRangeTop} ${x(props.secondaryRange.upperBound)},${margin.secondaryRangeTop} ${x(props.secondaryRange.upperBound)},${margin.top/2}`} />
                </g>
            }
            {/* bars */}
            {
                data.map((datum, index) => {
                    return <g
                        key={index}
                        transform={`translate(${margin.left},0)`}>
                            <rect
                                strokeWidth={2}
                                stroke={props.barStrokeColor}
                                fill={props.barFillColor}
                                fillOpacity={props.barFillOpacity}
                                x={data.length-1 > props.numDists ? x((index/2)+.5) : x(index)}
                                y={y(datum)}
                                height={height-margin.bottom-y(datum)}
                                width={data.length-1 > props.numDists ? x(1.5)-x(1) : x(2)-x(1)}
                            />
                    </g>    
                })
            }
            {/* indicator */}
            <g transform={`translate(${margin.left+(x(1)/2)}, -10)`}>
                <polygon
                    stroke={'red'}
                    fill={bandContainingMapIndex && props.bands[bandContainingMapIndex].color ? props.bands[bandContainingMapIndex].color : 'red'}
                    points={`${x(props.value)},${margin.top} ${x(props.value)-10},${margin.top-14} ${x(props.value)+10},${margin.top-14}`} className="triangle" />
                <line
                    stroke={"black"}
                    strokeWidth={5}
                    strokeDasharray={"2 3"}
                    x1={x(props.value)}
                    y1={margin.top}
                    x2={x(props.value)}
                    y2={height-20}
                />
            </g>
            <g transform={`translate(${margin.left+(x(1)/2)}, ${height-margin.bottom})`}>
                <Axis
                    scale={x}
                    orient={Orient.bottom}
                    ticks={width < 400 ? [6] : [10]}
                    // ticks={[props.numDists]}
                    tickTextProps={{
                        fontSize: width < 400 ? 16 : 18,
                    }}
                    tickFormat={e => e % 1 === 0 ? d3.format("d")(e) : null}
                />
                <text
                    x={(width-margin.right-margin.left)/1.5}
                    y={margin.bottom}
                    textAnchor="center">
                        {props.xLabel}
                </text>
            </g>
            <g transform={`translate(${margin.left},0)`}>
                <Axis
                    scale={y}
                    orient={Orient.left}
                    ticks={[3]}
                    tickFormat={(d) => d/1000 > 1 ? `${d/1000}k` : d}
                    tickSize={0}
                    tickTextProps={{
                        fontSize: 18,
                    }}
                />
                <text
                    x={-margin.left}
                    y={margin.top/1.5}
                    textAnchor="start">
                        {props.yLabel}
                </text>
            </g>
            <title>
                # of Districts: {Number.isInteger(props.value) ? props.value : props.value.toFixed(3)} | 
                Grade: {bandContainingMapIndex && props.bands[bandContainingMapIndex].title} |
                Fair Range: ({props.secondaryRange.lowerBound.toFixed(3)}-{props.secondaryRange.upperBound.toFixed(3)})
            </title>
        </svg>
    </div>
}
export default HistoBar;