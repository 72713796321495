import { REPORT_CARD_COLORS } from "../constants/colors-dashboard";

const sample = {
        "compactnessThresholds": {
            "A": [
                0,
                711.5
            ],
            "C": [
                711.5,
                1067.5
            ],
            "F": [
                1067.5,
                7639
            ]
        },
        "countySplitsThresholds": {
            "PASS": [
                2,
                26.5
            ],
            "FAIL": [
                26.5,
                100
            ]
        },
        "competitivenessThreshold": 0.0,
        "racialVAPreference": [
            [
                3.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0
            ],
            [
                1.0,
                1.0,
                0.0,
                0.0,
                0.0,
                0.0
            ],
            [
                1.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0
            ],
            [
                1.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0
            ],
            [
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0
            ],
            [
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0
            ]
        ],
        "partisanScoreThresholds": {
            "SeatsThreshold": [
                5.0,
                6.0
            ],
            "SymmetryThreshold": [
                5.23376953412029,
                6.533769534120291
            ]
        }
    }

// based on https://github.com/PrincetonUniversity/EIL-Dashboard/blob/master/NC/NC%20Report%20Cards/Report%20Cards/NC%20Cong19Dist%20score.json
export default class DashboardThresholds {
    constructor(jsonObj, numDists, ActualWins, sampleData=false) {
        if (sampleData) {
            jsonObj = sample;
        }
        this.compactness = jsonObj['compactnessThresholds'] ? jsonObj['compactnessThresholds'] : null;
        this.countySplits = jsonObj['countySplitsThresholds'] ? jsonObj['countySplitsThresholds'] : null;
        this.competitiveness = jsonObj['competitivenessThreshold'] ? jsonObj['competitivenessThreshold'] : null;
        this.racialVAPPreference = jsonObj['racialVAPreference'] ? jsonObj['racialVAPreference'] : null;
        this.partisanScore = jsonObj['partisanScoreThresholds'] ? jsonObj['partisanScoreThresholds'] : null;
        this.partisanGradeRanges = jsonObj['partisanGradeRanges'] ? jsonObj['partisanGradeRanges'] : null;
        this.winsFreq = jsonObj['winsFreq'] ? jsonObj['winsFreq'] : null;
        this.compFreq = jsonObj['compFreq'] ? jsonObj['compFreq'] : null;
        
        this.winsFreqReverse = [...this.winsFreq].reverse();
        this.compFreqReverse = [...this.compFreq].reverse();
        this.partisanGradeRangesReverse = [];
        let partisanGradeReverseIndex = Number.isInteger(ActualWins) ? numDists + 1 : numDists;
        Object.keys(this.partisanGradeRanges).forEach((grade) => {
            this.partisanGradeRangesReverse[grade] = [...this.partisanGradeRanges[grade]].reverse().map(val => (partisanGradeReverseIndex)-val);
        });
        this.partisanScore.SymmetryThresholdReverse = [...this.partisanScore.SymmetryThreshold].reverse().map(val => (numDists)-val)
    }
    /*
        color scale format
        {   
            lowerBound: .50,
            upperBound: 1,
            title: `(50%+ ${selectedVAP.toLocaleUpperCase()})`,
            color: VAP_COLORS.FIFTY_SIXTY_PERCENT
        },
    */

    /*
        input fields for these will be in the format
        {"A": [lower, upper], "B": [lower, upper], ...}
        or 
        {"PASS": [lower, upper], "FAIL": [lower, upper]}
    */
    getGradedColorScale = (field, normalizeBoundsFn=null) => {
        if (!field) {
            return;
        }
        let output = [];
        output = Object.keys(field).map((grade) => {
            let lowerBound = field[grade][0];
            let upperBound = field[grade][1];
            if (normalizeBoundsFn) {
                lowerBound = normalizeBoundsFn(lowerBound);
                upperBound = normalizeBoundsFn(upperBound);
            }
            return {
                lowerBound,
                upperBound,
                title: grade,
                color: REPORT_CARD_COLORS[grade]
            }
        })
        return output;
    }
    /*
        input fields for these will be in the format
        [lower, upper]
    */
    getRangeColorScale = (field, normalizeBoundsFn=null, label=null) => {
        if (!field) {
            return;
        }
        let lowerBound = field[0];
        let upperBound = field[1];
        if (normalizeBoundsFn) {
            lowerBound = normalizeBoundsFn(lowerBound);
            upperBound = normalizeBoundsFn(upperBound);
        }
        return {
            lowerBound,
            upperBound,
            title: `Pass ${label && ` (${label})`}`,
            color: REPORT_CARD_COLORS.PASS
        }
    }
    
    getCompactnessColorScale = () => {
        return this.getGradedColorScale(this.compactness);
    }
    
    getCompetitivenessColorScale = () => {
        return this.getGradedColorScale(this.competitiveness);
    }

    getCountySplitsColorScale = () => {
        return this.getGradedColorScale(this.countySplits);
    }

    getPartisanGradesColorScale = () => {
        return this.getGradedColorScale(this.partisanGradeRanges);
    }

    getReversePartisanGradesColorScale = () => {
        return this.getGradedColorScale(this.partisanGradeRangesReverse);
    }

    getSeatShareColorScale = () => {
        if (this.partisanScore && this.partisanScore.SeatsThreshold) {
            return this.getRangeColorScale(
                this.partisanScore.SeatsThreshold,
                null,
                // (x) => Number.parseFloat(Number.parseFloat(x*100).toPrecision(5))
                "Baseline"
            );
        }
    }
    getSymmetryColorScale = () => {
        if (this.partisanScore && this.partisanScore.SymmetryThreshold) {
            return this.getRangeColorScale(
                this.partisanScore.SymmetryThreshold,
                null,
                // (x) => Number.parseFloat(Number.parseFloat(x*100).toPrecision(5))
                "Normative"
            );
        }
    }
    getReverseSymmetryColorScale = () => {
        if (this.partisanScore && this.partisanScore.SymmetryThresholdReverse) {
            return this.getRangeColorScale(
                this.partisanScore.SymmetryThresholdReverse,
                null,
                // (x) => Number.parseFloat(Number.parseFloat(x*100).toPrecision(5))
                "Normative"
            );
        }
    }

    getPartisanBiasColorScale = () => {
        if (this.partisanScore && this.partisanScore.PBThreshold) {
            return this.getRangeColorScale(
                this.partisanScore.PBThreshold,
                (x) => Number.parseFloat(Number.parseFloat(x*100).toPrecision(5))
            );
        }
    }

    getMeanMedianColorScale = () => {
        if (this.partisanScore && this.partisanScore.MMThreshold) {
            return this.getRangeColorScale(
                this.partisanScore.MMThreshold,
                (x) => Number.parseFloat(Number.parseFloat(x*100).toPrecision(5))
            );
        }
    }

    getAvgWinDiffColorScale = () => {
        if (this.partisanScore && this.partisanScore.AvgWinDiffThreshold) {
            return this.getRangeColorScale(this.partisanScore.AvgWinDiffThreshold);
        }
    }
}